import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";

const ProductHuntUpdateUser = ({ modalStatus, onHide, user, onSubmit }) => {
  const { register, handleSubmit, reset } = useForm();
  const { name, email } = user || {};
  useEffect(() => {
    reset({
      name,
      email,
    });
  }, [user]);
  return (
    <Modal show={modalStatus} onHide={onHide}>
      <Modal.Header>
        <h3>{`Updating ${name}`}</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              className="form-control"
              name="name"
              id="name"
              type="text"
              ref={register({ required: true })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Email</label>
            <input
              className="form-control"
              name="email"
              id="email"
              type="email"
              ref={register({ required: true })}
            />
          </div>
          <hr />
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ProductHuntUpdateUser;
