import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CardOverlayLoading from "../../../../UI-components/Card/CardOverlayLoading";
import CardHeader from "../../../../UI-components/Card/CardHeader";
import CardActionButton from "../../../../UI-components/Card/CardActionButton";
import paginationOptions from "../../../../UI-components/Tables/Pagination";
import {
  isTasksLoadingSelector,
  tasksSelector,
} from "../../Management-redux/Management-selectors";
import setTasksColumns from "./TasksTable-columns";
import {
  startDeleteTask,
  startGetTasks,
  startUpdateTask,
} from "../../Management-redux/Management-actions";
import ManageTaskModal, { prioOptions } from "../modals/ManageTaskModal";

const TasksTable = () => {
  const clientFilterRef = useRef();
  const priorityFilterRef = useRef();
  const [reloadCounter, setReloadCounter] = useState(0);
  const [filters, setFilters] = useState({
    clientFilter: null,
    priorityFilter: null,
  });
  const [priorityFilterValue, setPriorityFilterValue] = useState(null);
  const { username = "" } = useParams();
  const [modalStatus, setModalStatus] = useState(false);
  const [managedTask, setManagedTask] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector(isTasksLoadingSelector);
  const tasks = useSelector(tasksSelector);
  const handleShowModal = (task) => {
    setModalStatus(true);
    setManagedTask(task);
  };
  const handleShowModalNew = () => {
    setModalStatus(true);
  };
  const handleHideModal = () => {
    setManagedTask(null);
    setModalStatus(false);
  };
  const handleUpdate = (updatedTask, taskId) => {
    dispatch(startUpdateTask(updatedTask, taskId, handleHideModal, username));
  };
  const handleDelete = (taskId) => dispatch(startDeleteTask(taskId, username));
  const handleReloadData = () =>
    setReloadCounter((prevCounter) => prevCounter + 1);
  const columns = setTasksColumns(
    handleUpdate,
    handleDelete,
    handleShowModal,
    setFilters,
    username
  );
  useEffect(() => {
    const fetchAll = !username;
    dispatch(startGetTasks(username, fetchAll));
  }, [reloadCounter]);
  const clearFilters = () => {
    const { priorityFilter, clientFilter } = filters;
    if (!username) {
      clientFilterRef.current.value = "";
      clientFilter("");
    }
    setPriorityFilterValue(null);
    priorityFilter("");
  };
  return (
    <div
      className="col-lg-12 position-relative"
      style={{ overflowX: "auto", overflow: "visible" }}
    >
      <div style={{ minWidth: "1200px", position: "relative" }}>
        <CardOverlayLoading isLoading={isLoading} />
        <div className="card card-custom gutter-b">
          <CardHeader title="Tasks">
            <div className="d-flex">
              <CardActionButton
                username={username}
                text="Add Task"
                btnClass="btn btn-outline-success mr-2"
                icon="fa fa-tasks"
                action={handleShowModalNew}
              />
              <CardActionButton
                username={username}
                text="Reload"
                icon="fa fa-sync"
                btnClass="btn btn-light-primary font-weight-bold"
                action={handleReloadData}
              />
            </div>
          </CardHeader>
          <div className="row m-5">
            <div className="col-8">
              <div className="row align-items-center">
                {!username && (
                  <div className="col-4 d-flex align-items-center">
                    <label className="mr-1" htmlFor="clientFilter">
                      Client:
                    </label>
                    <input
                      name="clientFilter"
                      ref={clientFilterRef}
                      className="form-control"
                      placeholder="Filter Clients"
                      onChange={(e) => {
                        filters.clientFilter(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className="col-4">
                  <Select
                    isClearable
                    value={priorityFilterValue}
                    ref={priorityFilterRef}
                    placeholder="Priority"
                    options={prioOptions}
                    onChange={(val) => {
                      setPriorityFilterValue(val);
                      const { value } = val || {};
                      if (!value) return filters.priorityFilter("");
                      return filters.priorityFilter(value);
                    }}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body pt-0 pb-3 ">
            <BootstrapTable
              filter={filterFactory()}
              pagination={paginationFactory(paginationOptions)}
              columns={columns}
              data={tasks}
              keyField="id"
              bordered={false}
              bootstrap4
            />
          </div>
          <ManageTaskModal
            modalStatus={modalStatus}
            onHide={handleHideModal}
            managedTask={managedTask}
          />
        </div>
      </div>
    </div>
  );
};

export default TasksTable;
