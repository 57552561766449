import { put, fork, take, cancel, call, takeEvery } from "redux-saga/effects";
import * as types from "./auth-actionTypes";
import * as actions from "./auth-actions";
import AuthApi from "../../../API/Auth-API/Auth-Api";
import {
  clearStorageItem,
  getStorageUser,
  storeUser,
} from "../../../StorageAPI/StorageAPI";
import User from "../../../API/Auth-API/AuthModel";

function* authorizeSaga(loginForm) {
  try {
    const { data } = yield call(AuthApi.login, loginForm);
    const { user, token, roles } = data.data;
    yield call(storeUser, user, token, roles); // Blocking Code
    const userModel = new User({ ...user, roles });
    yield put(actions.loginSuccess(userModel));
  } catch (error) {
    yield put(actions.loginFail("Something Went Wrong"));
  } finally {
    yield put(actions.loginReset());
  }
}
function* autoLoginSaga() {
  const authStorage = getStorageUser();
  if (!authStorage) return;
  try {
    const { user, roles } = authStorage;
    const userModel = new User({ ...user, roles });
    yield put(actions.loginSuccess(userModel));
  } catch (error) {
    console.log(error);
  }
}
function* logoutSaga() {
  try {
    yield call(clearStorageItem, "user");
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFail());
  }
}

export function* loginFlow() {
  while (true) {
    const authStorage = getStorageUser();
    let authTask;
    if (!authStorage) {
      const { loginForm } = yield take(types.LOGIN_START);
      authTask = yield fork(authorizeSaga, loginForm);
    }
    const action = yield take([
      types.LOGOUT_START,
      types.LOGIN_FAIL,
      types.LOGIN_START,
    ]);
    if (action.type === types.LOGOUT_START) {
      if (!authStorage) {
        yield cancel(authTask);
      }
      yield call(logoutSaga);
    }
  }
}
export function* autoLogin() {
  yield takeEvery(types.AUTO_LOGIN_START, autoLoginSaga);
}
