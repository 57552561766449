import React, { useContext } from "react";
import PHContext from "../../../../../../../contexts/ProductHuntWizardContext";
import ProductHuntWizardFooter from "../../../ProductHuntWizardFooter/ProductHuntWizardFooter";
import { APP_URL_DEFAULT } from "../../../../../../../API/API-config";

const PHStepSummary = () => {
  const { productHuntForm } = useContext(PHContext);
  const {
    productHuntLink,
    projectName,
    projectDescription,
    projectImage,
  } = productHuntForm;
  return (
    <div>
      <h1>Summary</h1>
      <img
        className="my-5"
        style={{ maxHeight: "150px" }}
        src={`${APP_URL_DEFAULT}/${projectImage}`}
        alt="product"
      />
      <div className="mt-2">
        <span className="mr-2">Product Name:</span>
        <span className="font-weight-bold">{projectName}</span>
      </div>
      <div className="mt-2">
        <span className="mr-2">Product Description:</span>
        <span className="font-weight-bold">{projectDescription}</span>
      </div>
      <div className="mt-2">
        <span className="mr-2">ProductHunt Link:</span>
        <a
          className="btn btn-link-primary font-weight-bold"
          href={productHuntLink}
          rel="noreferrer"
          target="_blank"
        >
          {`${productHuntLink.slice(0, 50)}...`}
        </a>
      </div>
      <ProductHuntWizardFooter />
    </div>
  );
};

export default PHStepSummary;
