import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const ClientListItemFooter = ({ username }) => {
  const { push } = useHistory();
  return (
    <div className="card-footer d-flex align-items-center justify-content-around px-0">
      <button
        className="btn btn-outline-info"
        type="button"
        onClick={() => push(`/clients/${username}/dashboard`)}
      >
        Client Dashboard
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => push(`clients/${username}/campaign`)}
      >
        Campaign
      </button>
    </div>
  );
};

ClientListItemFooter.defaultProps = {
  username: "",
};
ClientListItemFooter.propTypes = {
  username: PropTypes.string,
};

export default ClientListItemFooter;
