import React from "react";

const columns = (
  setUserVoteStatus,
  setUserCommentStatus,
  voteKind,
  commentKind
) => [
  {
    dataField: "id",
    text: "ID",
    hidden: true,
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "shouldVote",
    text: "Vote",
    formatter: (shouldVote, user) => (
      <button
        onClick={() => {
          if (!voteKind) {
            setUserVoteStatus(user.id);
          }
        }}
        type="button"
        className="btn btn-light btn-icon"
      >
        {shouldVote ? (
          <i className="fa fa-check-circle text-success" />
        ) : (
          <i className="far fa-circle text-warning" />
        )}
      </button>
    ),
  },
  {
    dataField: "shouldComment",
    text: "Comment",
    formatter: (shouldComment, user) => (
      <button
        onClick={() => {
          if (!commentKind) {
            setUserCommentStatus(user.id);
          }
        }}
        type="button"
        className="btn btn-light btn-icon"
      >
        {shouldComment ? (
          <i className="fa fa-check-circle text-success" />
        ) : (
          <i className="far fa-circle text-warning" />
        )}
      </button>
    ),
  },
];

export default columns;
