import React from "react";
import { useHistory } from "react-router-dom";
import { APP_URL_DEFAULT } from "../../../../../API/API-config";
import ProjectItemActionsDropdown from "./ProductItemActionsDropdown/ProductItemActionsDropdown";

const ProductHuntProjectItem = ({ product }) => {
  const { push } = useHistory();
  const { image, title, description, commentsCount, votesCount, url, id } =
    product || {};
  return (
    <div className="col-xl-6 col-md-12">
      <div className="card card-custom gutter-b card-stretch">
        <div className="card-body d-flex flex-column justify-content-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <img
                src={`${APP_URL_DEFAULT}${image}`}
                alt="product hunt"
                style={{ maxHeight: "65px", maxWidth: "100px" }}
              />
            </div>
            <a
              className="text-dark text-hover-primary font-size-h2 font-weight-bolder mb-1 ml-2"
              href={url}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
            <ProjectItemActionsDropdown product={product} />
          </div>
          <hr className="w-100" />
          <div className="mb-10 mt-5 font-weight-bold">{description}</div>

          <div className="d-flex justify-content-center mt-10">
            <button className="btn btn-outline-info" type="button">
              <i className="fa fa-comment" />
              {commentsCount}
            </button>
            <button className="btn btn-outline-primary ml-3" type="button">
              <i className="fa fa-thumbs-up" />
              {votesCount}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHuntProjectItem;
