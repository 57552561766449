import { createSelector } from "reselect";

export const callsSelector = (state) => state.managerManagement.calls;
export const managerManagementLoadSelector = (state) =>
  state.managerManagement.loadingStates;
export const tasksSelector = (state) => state.managerManagement.tasks;

export const isCallsLoadingSelector = createSelector(
  [managerManagementLoadSelector],
  ({ getCalls, createCall, updateCall, deleteCall }) =>
    !!(getCalls || createCall || updateCall || deleteCall)
);
export const isTasksLoadingSelector = createSelector(
  [managerManagementLoadSelector],
  ({ getTasks, createTask, updateTask, deleteTask }) =>
    !!(getTasks || createTask || updateTask || deleteTask)
);
