import React from "react";
import PropTypes from "prop-types";

const CampaignProgressLabel = ({ name, completed, goalTarget }) => {
  let progress = 0;
  if (goalTarget) {
    progress = Math.floor((completed / goalTarget) * 100);
  }
  let iconAndColor = {
    color: "",
    icon: "",
  };
  const getIconAndColor = () => {
    switch (name) {
      case "youtube":
        iconAndColor = {
          color: "#FF0000",
          icon: "fab fa-youtube",
        };
        break;
      case "twitter":
        iconAndColor = {
          color: "#1DA1F2",
          icon: "fab fa-twitter",
        };
        break;
      case "reddit":
        iconAndColor = {
          color: "#FF5700",
          icon: "fab fa-reddit",
        };
        break;
      default:
        iconAndColor = {
          color: "#8950FC",
          icon: "far fa-newspaper",
        };
        break;
    }
  };
  getIconAndColor();
  const { icon, color } = iconAndColor;
  const isComplete = progress === 100;
  return (
    <span
      className="label label-lg label-inline font-size-lg position-relative"
      style={{ fontWeight: "700" }}
    >
      <i
        className={`${icon} mr-2`}
        style={{ color: isComplete ? "#fff" : color, zIndex: "10" }}
      />
      <span className={`zindex-5 ${isComplete ? "text-white" : ""}`}>
        {`${completed} / ${goalTarget}`}
      </span>
      <span
        style={{
          position: "absolute",
          left: "0",
          width: `${progress}%`,
          height: "100%",
          background: color,
          opacity: isComplete ? 1 : 0.2,
        }}
      />
    </span>
  );
};

CampaignProgressLabel.propTypes = {
  name: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  goalTarget: PropTypes.number.isRequired,
};

export default CampaignProgressLabel;
