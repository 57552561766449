import React from "react";
import PropTypes from "prop-types";
import Client from "../../../../../../DataModels/Client";

const ClientListItemLabels = ({ client, isCompleted }) => {
  return (
    <>
      <div className="mb-10 mt-5 font-weight-bold">Description</div>
      <div className="d-flex mb-5">
        <div className="d-flex align-items-center mr-7">
          {client.paymentLabelJSX()}
        </div>
        {isCompleted && (
          <div className="d-flex align-items-center mr-7">
            <span className="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">
              Completed
            </span>
          </div>
        )}
      </div>
    </>
  );
};

ClientListItemLabels.defaultProps = {
  isCompleted: false,
};
ClientListItemLabels.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
  isCompleted: PropTypes.bool,
};

export default ClientListItemLabels;
