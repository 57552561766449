import * as types from "./Management-actionTypes";

export const startGetTasks = (taskTarget, fetchAll) => ({
  type: types.START_GET_TASKS,
  taskTarget,
  fetchAll,
});
export const startCreateTask = (task, taskTarget, onHide, fetchAll) => ({
  type: types.START_CREATE_TASK,
  task,
  taskTarget,
  onHide,
  fetchAll,
});
export const startUpdateTask = (updatedTask, taskId, onHide, taskTarget) => ({
  type: types.START_UPDATE_TASK,
  updatedTask,
  taskId,
  onHide,
  taskTarget,
});
export const startDeleteTask = (taskId, taskTarget) => ({
  type: types.START_DELETE_TASK,
  taskId,
  taskTarget,
});

export const successGetTasks = (tasks) => ({
  type: types.SUCCESS_GET_TASKS,
  tasks,
});
export const successCreateTask = () => ({
  type: types.SUCCESS_CREATE_TASK,
});
export const successUpdateTask = () => ({
  type: types.SUCCESS_UPDATE_TASK,
});
export const successDeleteTask = () => ({
  type: types.SUCCESS_DELETE_TASK,
});

export const failGetTasks = (errorMsg) => ({
  type: types.FAIL_GET_TASKS,
  errorMsg,
});
export const failCreateTask = (errorMsg) => ({
  type: types.FAIL_CREATE_TASK,
  errorMsg,
});
export const failUpdateTask = (errorMsg) => ({
  type: types.FAIL_UPDATE_TASK,
  errorMsg,
});
export const failDeleteTask = (errorMsg) => ({
  type: types.FAIL_DELETE_TASK,
  errorMsg,
});
export const startGetCalls = (username) => ({
  type: types.START_GET_CALLS,
  username,
});
export const startCreateCall = (newCall, onHide, username) => ({
  type: types.START_CREATE_CALL,
  newCall,
  onHide,
  username,
});
export const startUpdateCall = (updatedCall, callId, onHide, username) => ({
  type: types.START_UPDATE_CALL,
  updatedCall,
  callId,
  onHide,
  username,
});
export const startDeleteCall = (callId, username) => ({
  type: types.START_DELETE_CALL,
  callId,
  username,
});

export const successGetCalls = (calls) => ({
  type: types.SUCCESS_GET_CALLS,
  calls,
});
export const successCreateCall = () => ({
  type: types.SUCCESS_CREATE_CALL,
});
export const successUpdateCall = () => ({
  type: types.SUCCESS_UPDATE_CALL,
});
export const successDeleteCall = () => ({
  type: types.SUCCESS_DELETE_CALL,
});

export const failGetCalls = (errorMsg) => ({
  type: types.FAIL_GET_CALLS,
  errorMsg,
});
export const failCreateCall = (errorMsg) => ({
  type: types.FAIL_CREATE_CALL,
  errorMsg,
});
export const failUpdateCall = (errorMsg) => ({
  type: types.FAIL_UPDATE_CALL,
  errorMsg,
});
export const failDeleteCall = (errorMsg) => ({
  type: types.FAIL_DELETE_CALL,
  errorMsg,
});
