import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ClientListItemHeader from "./ClientListItem-components/ClientListItemHeader";
import ClientListItemLabels from "./ClientListItem-components/ClientListItemLabels";
import ClientListItemProgress from "./ClientListItem-components/ClientListItemProgress";
import ClientListItemFooter from "./ClientListItem-components/ClientListItemFooter";
import Client from "../../../../../DataModels/Client";

const ClientListItemContainer = styled.div`
  box-shadow: ${({ containerShadow }) => containerShadow}
  opacity: ${({ containerOpacity }) => containerOpacity} 

`;

const ClientListItem = ({ client }) => {
  const { username, image, isCompleted, progress, isPaid, icount } = client;
  const containerStyledProps = {
    containerOpacity: isCompleted ? 0.6 : 1,
    containerShadow: isPaid === 3 ? "0px 0px 11px #ff4f4f !important;" : "",
  };
  return (
    <ClientListItemContainer
      className="card card-custom gutter-b card-stretch hover-opacity-100"
      {...containerStyledProps}
    >
      <div className="card-body">
        <ClientListItemHeader
          icount={icount}
          image={image}
          username={username}
        />
        <ClientListItemLabels isCompleted={isCompleted} client={client} />
        <ClientListItemProgress isCompleted={isCompleted} progress={progress} />
        <ClientListItemFooter username={username} />
      </div>
    </ClientListItemContainer>
  );
};

ClientListItem.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
};

export default ClientListItem;
