import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";

import { toastr } from "react-redux-toastr";
import PHContext from "../../../../../../../contexts/ProductHuntWizardContext";
import ProductHuntWizardFooter from "../../../ProductHuntWizardFooter/ProductHuntWizardFooter";
import {
  PRODUCTHUNT_ALL_USERS_COMMENT,
  PRODUCTHUNT_ALL_USERS_VOTE,
  PRODUCTHUNT_ALL_USERS_VOTE_COMMENT,
} from "../../../../../../../constants/constants";
import columns from "./PHStepUsers-columns";
import ProductHuntAPI from "../../../../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const PHStepUsers = () => {
  const { nextStep, setProductHuntForm } = useContext(PHContext);
  const [users, setUsers] = useState([]);
  const [voteKind, setVoteKind] = useState(PRODUCTHUNT_ALL_USERS_VOTE);
  const [commentKind, setCommentKind] = useState(PRODUCTHUNT_ALL_USERS_COMMENT);
  const whoShouldVoteOptions = [
    { label: "Everyone", value: PRODUCTHUNT_ALL_USERS_VOTE },
    { label: "Choose Users", value: "" },
  ];
  const whoShouldCommentOptions = [
    {
      label: "Everyone",
      value: PRODUCTHUNT_ALL_USERS_COMMENT,
    },
    { label: "Choose Users", value: "" },
  ];
  const setUserVoteStatus = (userId) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, shouldVote: !user.shouldVote };
        }
        return user;
      });
    });
  };
  const setUserCommentStatus = (userId) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, shouldComment: !user.shouldComment };
        }
        return user;
      });
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const setAssignedKind = () => {
      if (voteKind && commentKind) {
        return PRODUCTHUNT_ALL_USERS_VOTE_COMMENT;
      }
      return null;
    };
    setProductHuntForm((prevState) => ({
      ...prevState,
      assignKind: setAssignedKind(),
      members: users,
    }));
    nextStep();
  };

  useEffect(() => {
    ProductHuntAPI.getUsers()
      .then((res) => {
        const usersFormat = res.data.data.users.map((user) => ({
          id: user.id,
          name: user.name,
          shouldComment: true,
          shouldVote: true,
        }));
        setUsers(usersFormat);
      })
      .catch(() => toastr.error("Fetching Users Failed"))
      .finally(() => {});
  }, []);
  useEffect(() => {
    if (voteKind === PRODUCTHUNT_ALL_USERS_VOTE) {
      return setUsers((prevUsers) => {
        return prevUsers.map((user) => ({
          ...user,
          shouldVote: true,
        }));
      });
    }
    return setUsers((prevUsers) => {
      return prevUsers.map((user) => ({
        ...user,
        shouldVote: false,
      }));
    });
  }, [voteKind]);
  useEffect(() => {
    if (commentKind === PRODUCTHUNT_ALL_USERS_COMMENT) {
      return setUsers((prevUsers) => {
        return prevUsers.map((user) => ({
          ...user,
          shouldComment: true,
        }));
      });
    }
    return setUsers((prevUsers) => {
      return prevUsers.map((user) => ({
        ...user,
        shouldComment: false,
      }));
    });
  }, [commentKind]);
  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <h3 className="mb-10 font-weight-bold text-dark">Users To Vote:</h3>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          htmlFor="projectName"
        >
          Who Should Vote:
        </label>
        <div className="col-lg-9 col-xl-9">
          <Select
            onChange={(e) => setVoteKind(e.value)}
            options={whoShouldVoteOptions}
            defaultValue={whoShouldVoteOptions[0]}
          />
        </div>
      </div>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          htmlFor="projectName"
        >
          Who Should Comment:
        </label>
        <div className="col-lg-9 col-xl-9">
          <Select
            onChange={(e) => setCommentKind(e.value)}
            options={whoShouldCommentOptions}
            defaultValue={whoShouldCommentOptions[0]}
          />
        </div>
      </div>
      <div className="mt-5">
        {(!voteKind || !commentKind) && (
          <BootstrapTable
            columns={columns(
              setUserVoteStatus,
              setUserCommentStatus,
              voteKind,
              commentKind
            )}
            data={users}
            keyField="id"
            bordered={false}
            bootstrap4
          />
        )}
      </div>
      <ProductHuntWizardFooter />
    </form>
  );
};

export default PHStepUsers;
