import React from "react";
import PropTypes from "prop-types";
import { APP_URL_DEFAULT } from "../../../API/API-config";

const AvatarImage = ({ username = "", image }) => {
  let avatar;
  if (username === "IJ") {
    avatar = `url(${image})`;
  } else {
    avatar = `url(${APP_URL_DEFAULT}${image})`;
  }
  return (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-40 flex-shrink-0">
        <div
          className="symbol-label"
          style={{
            backgroundImage: avatar,
          }}
        />
      </div>
      <div className="ml-2">
        <div className="text-dark-75 font-weight-bold line-height-sm">
          {username}
        </div>
      </div>
    </div>
  );
};

AvatarImage.defaultProps = {
  username: "",
};
AvatarImage.propTypes = {
  username: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default AvatarImage;
