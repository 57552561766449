import React, { useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../../../containers/Auth/auth-redux/auth-selectors";
import { isTasksLoadingSelector } from "../../Management-redux/Management-selectors";
import {
  clientsSelector,
  managersSelector,
} from "../../../../../../redux/General-redux/General-selectors";
import {
  startCreateTask,
  startUpdateTask,
} from "../../Management-redux/Management-actions";
import TaskModel from "../../../../../DataModels/TaskModel";
import { priorityToString } from "../../../../../utils/models-utils";
import SelectComp from "../../../../UI-components/Controllers/Select/Select";
import ModalFooter from "../../../../UI-components/Modals/ModalFooter";
import DatePickerComp from "../../../../UI-components/Controllers/DatePicker/DatePicker";
import { inputValidationState } from "../../../../UI-components/Formik/Formik-utils/Formik-utils";

export const prioOptions = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
];

const ManageTaskModal = ({ modalStatus, onHide, managedTask }) => {
  const setInitialValues = () => {
    if (managedTask) {
      const { title, taskDateAt, users, priority } = managedTask;
      return {
        title,
        date: moment(taskDateAt).format("DD-MM-YYYY"),
        priority,
        status: 1,
        assignedManagers: users.map((el) => ({
          value: el.username,
          label: el.user,
        })),
      };
    }
    return {
      title: "",
      date: moment().format("DD-MM-YYYY"),
      priority: "1",
      status: "1",
      isAssigned: false,
      assignedManagers: [],
    };
  };
  const { register, errors, handleSubmit, control, reset } = useForm({
    defaultValues: setInitialValues(),
  });
  const { username = "" } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isLoading = useSelector(isTasksLoadingSelector);
  const managerOptions = useSelector(managersSelector).map((manager) => ({
    value: manager.username,
    label: manager.name,
  }));
  const targetOptions = [
    { value: "general", label: "General" },
    ...useSelector(clientsSelector).map((client) => ({
      value: client.username,
      label: client.username,
    })),
  ];
  const defaultPriority = () => {
    if (managedTask) {
      return {
        label: priorityToString(managedTask.priority),
        value: managedTask.priority,
      };
    }
    return {
      label: "Low",
      value: 1,
    };
  };
  const defaultAssignedManagers = () => {
    if (managedTask) {
      return managedTask.users.map((el) => ({
        label: el.name,
        value: el.username,
      }));
    }
    return [];
  };
  const defaultTaskTarget = () => {
    if (managedTask && managedTask.client) {
      // eslint-disable-next-line no-shadow
      const { username } = managedTask.client;
      return { label: username, value: username };
    }
    return targetOptions[0];
  };
  const onSubmit = (taskValues) => {
    const {
      assignedManagers,
      date,
      title,
      priority,
      taskTarget: target,
    } = taskValues;
    let responsibility = [user.username];
    if (assignedManagers.length) {
      responsibility = assignedManagers;
    }
    const taskForm = {
      title,
      status: managedTask ? managedTask.status : 1,
      priority,
      users: responsibility,
      task_date_at: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };
    if (managedTask) {
      return dispatch(
        startUpdateTask(taskForm, managedTask.id, onHide, username)
      );
    }
    const fetchAll = !username;
    const taskTarget = username || target;
    return dispatch(
      startCreateTask({ form: taskForm }, taskTarget, onHide, fetchAll)
    );
  };
  useEffect(() => {
    reset(setInitialValues());
  }, [managedTask]);
  return (
    <Modal show={modalStatus} onHide={onHide}>
      <Modal.Header>
        <h3 className="modal-title">Manage Task</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-group">
            <SelectComp
              name="priority"
              options={prioOptions}
              defaultValue={defaultPriority()}
              label="Priority"
              control={control}
            />
          </div>
          <div className="form-group">
            <label className="d-block" htmlFor="date">
              Date
            </label>
            <DatePickerComp control={control} />
          </div>
          {!username && (
            <div className="form-group">
              <SelectComp
                name="taskTarget"
                control={control}
                options={targetOptions}
                defaultValue={defaultTaskTarget()}
                label="Task Target"
              />
            </div>
          )}
          <div className="form-group">
            <SelectComp
              name="assignedManagers"
              options={managerOptions}
              defaultValue={defaultAssignedManagers()}
              label="Assign Manager"
              isMulti
              control={control}
            />
            <span className="text-muted">
              Will Be Self Assigned If Left Empty
            </span>
          </div>
          <div className="form-group">
            <label htmlFor="title">Description</label>
            <input
              defaultValue=""
              name="title"
              ref={register({ required: true })}
              className={inputValidationState()}
            />
            <ErrorMessage name="title" errors={errors} message="Required" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter isLoading={isLoading} onHide={onHide} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ManageTaskModal.defaultProps = {
  managedTask: PropTypes.object,
};
ManageTaskModal.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  managedTask: PropTypes.instanceOf(TaskModel),
};

export default ManageTaskModal;
