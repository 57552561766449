import { createSelector } from "reselect";

const authSelector = (state) => state.auth;

export const userSelector = createSelector(authSelector, ({ user }) => user);
export const userRoleSelector = createSelector(authSelector, ({ user }) => {
  const { roles } = user;
  let userRole = "";
  roles.forEach((role) => {
    if (role === "admin") {
      userRole = "admin";
    }
    if (role === "account.manager" && !userRole) {
      userRole = "manager";
    }
  });
  return userRole;
});

export const isAuthorizedSelector = createSelector(
  authSelector,
  (auth) => auth.user !== null
);
export const loginLoadingSelector = createSelector(authSelector, (auth) => {
  return auth.loadingStates.loadLogin;
});

export const getErrorSelector = (authType) => {
  return createSelector(
    authSelector,
    ({ errorStates }) => errorStates[authType]
  );
};
