export function priorityToString(priority) {
  switch (priority) {
    case 1:
      return "Low";
    case 2:
      return "Medium";
    case 3:
      return "High";
    default:
      return "Low";
  }
}
export function numToColor(priority) {
  switch (priority) {
    case 1:
      return "warning";
    case 2:
      return "success";
    case 3:
      return "danger";
    default:
      return "success";
  }
}

export function priorityToColor(priority) {
  switch (priority) {
    case 1:
      return "success";
    case 2:
      return "warning";
    case 3:
      return "danger";
    default:
      return "success";
  }
}

export function statusToString(status) {
  switch (status) {
    case 1:
      return "Scheduled";
    case 2:
      return "Completed";
    case 3:
      return "Canceled";
    default:
      return "Scheduled";
  }
}
