import produce from "immer";
import * as types from "./Management-actionTypes";

const initialState = {
  loadingStates: {
    getTasks: false,
    createTask: false,
    deleteTask: false,
    updateTask: false,
    getCalls: false,
    createCall: false,
    deleteCall: false,
    updateCall: false,
  },
  errorStates: {
    errorTasks: false,
    errorCalls: false,
  },
  tasks: [],
  calls: [],
};

// eslint-disable-next-line import/prefer-default-export
/* eslint-disable no-param-reassign */
const managementReducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case types.START_GET_TASKS:
      draft.loadingStates.getTasks = true;
      break;
    case types.SUCCESS_GET_TASKS:
      draft.loadingStates.getTasks = false;
      draft.tasks = action.tasks;
      break;
    case types.FAIL_GET_TASKS:
      draft.loadingStates.getTasks = false;
      draft.errorStates.errorTasks = action.errorMsg;
      break;

    case types.START_CREATE_TASK:
      draft.loadingStates.createTask = true;
      break;
    case types.SUCCESS_CREATE_TASK:
      draft.loadingStates.createTask = false;
      break;
    case types.FAIL_CREATE_TASK:
      draft.loadingStates.createTask = false;
      break;

    case types.START_UPDATE_TASK:
      draft.loadingStates.updateTask = true;
      break;
    case types.SUCCESS_UPDATE_TASK:
      draft.loadingStates.updateTask = false;
      break;
    case types.FAIL_UPDATE_TASK:
      draft.loadingStates.updateTask = false;
      break;

    case types.START_DELETE_TASK:
      draft.loadingStates.deleteTask = true;
      break;
    case types.SUCCESS_DELETE_TASK:
      draft.loadingStates.deleteTask = false;
      break;
    case types.FAIL_DELETE_TASK:
      draft.loadingStates.deleteTask = false;
      break;

    case types.START_GET_CALLS:
      draft.loadingStates.getCalls = true;
      break;
    case types.SUCCESS_GET_CALLS:
      draft.loadingStates.getCalls = false;
      draft.calls = action.calls;
      break;
    case types.FAIL_GET_CALLS:
      draft.loadingStates.getCalls = false;
      draft.errorStates.errorCalls = action.errorMsg;
      break;

    case types.START_CREATE_CALL:
      draft.loadingStates.createCall = true;
      break;
    case types.SUCCESS_CREATE_CALL:
      draft.loadingStates.createCall = false;
      break;
    case types.FAIL_CREATE_CALL:
      draft.loadingStates.createCall = false;
      draft.errorStates.errorCalls = action.errorMsg;
      break;

    case types.START_DELETE_CALL:
      draft.loadingStates.deleteCall = true;
      break;
    case types.SUCCESS_DELETE_CALL:
      draft.loadingStates.deleteCall = false;
      break;
    case types.FAIL_DELETE_CALL:
      draft.loadingStates.deleteCall = false;
      break;

    case types.START_UPDATE_CALL:
      draft.loadingStates.updateCall = true;
      break;
    case types.SUCCESS_UPDATE_CALL:
      draft.loadingStates.updateCall = false;
      break;
    case types.FAIL_UPDATE_CALL:
      draft.loadingStates.updateCall = false;
      break;
  }
}, initialState);

export default managementReducer;
