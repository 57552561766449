import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toastr } from "react-redux-toastr";
import ProductHuntWizardFooter from "../../../ProductHuntWizardFooter/ProductHuntWizardFooter";
import PHContext from "../../../../../../../contexts/ProductHuntWizardContext";
import GeneralAPI from "../../../../../../../API/General-API/General-API";
import defaultProjectImage from "../../../../../../../../assets/images/projectImage.jpg";
import { APP_URL_DEFAULT } from "../../../../../../../API/API-config";

const PHStepDetails = () => {
  const { nextStep, setProductHuntForm, productHuntForm } = useContext(
    PHContext
  );
  const { register, errors, handleSubmit, reset } = useForm();
  const { projectImage } = productHuntForm;
  const onImageUpload = (e) => {
    const image = e.target.files[0];
    GeneralAPI.uploadImage(image)
      .then((res) => {
        setProductHuntForm((prevForm) => ({
          ...prevForm,
          projectImage: res.data.data.filename,
        }));
      })
      .catch(() => toastr.error("Upload Image Failed"));
  };
  const onSubmit = (values) => {
    setProductHuntForm((prevForm) => ({
      ...prevForm,
      ...values,
    }));

    if (projectImage) {
      nextStep();
    } else {
      toastr.error("Please Upload A product Image");
    }
  };
  useEffect(() => {
    reset(productHuntForm);
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="mb-10 font-weight-bold text-dark">Project Details:</h3>
      <label
        className="form-group d-flex justify-content-between align-items-center"
        htmlFor="upload-image"
      >
        <span>Product Image</span>
        <div className="image-input image-input-outline" id="imageContainer">
          <img
            style={{ width: "auto", maxHeight: "120px" }}
            className="image-input-wrapper"
            src={
              (projectImage && `${APP_URL_DEFAULT}${projectImage}`) ||
              defaultProjectImage
            }
            alt="project"
          />
          <span
            data-action="change"
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          >
            <i className="fa fa-pen text-primary" />
          </span>
          <input
            type="file"
            style={{ display: "none" }}
            id="upload-image"
            onChange={onImageUpload}
            accept="image/png"
          />
        </div>
        <div />
      </label>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          htmlFor="productHuntLink"
        >
          Product Hunt Link
        </label>
        <div className="col-lg-9 col-xl-9">
          <input
            defaultValue=""
            className="form-control form-control-lg form-control-solid"
            name="productHuntLink"
            type="text"
            ref={register({ required: true })}
          />
          <ErrorMessage
            name="productHuntLink"
            errors={errors}
            message="Required"
          />
        </div>
      </div>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          htmlFor="projectName"
        >
          Project Name
        </label>
        <div className="col-lg-9 col-xl-9">
          <input
            defaultValue=""
            className="form-control form-control-lg form-control-solid"
            name="projectName"
            type="text"
            ref={register({ required: true })}
          />
          <ErrorMessage name="projectName" errors={errors} message="Required" />
        </div>
      </div>
      <div className="form-group row">
        <label
          className="col-xl-3 col-lg-3 col-form-label"
          htmlFor="projectDescription"
        >
          Project Description
        </label>
        <div className="col-lg-9 col-xl-9">
          <input
            defaultValue=""
            className="form-control form-control-lg form-control-solid"
            name="projectDescription"
            type="text"
            ref={register({ required: true })}
          />
          <ErrorMessage
            name="projectDescription"
            errors={errors}
            message="Required"
          />
        </div>
      </div>
      <ProductHuntWizardFooter />
    </form>
  );
};

export default PHStepDetails;
