import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  startGetClients,
  startGetManagers,
} from "../redux/General-redux/General-actions";
import { userRoleSelector } from "./containers/Auth/auth-redux/auth-selectors";
import AdminRoutes from "./containers/Admin/AdminRoutes/AdminRoutes";
import ManagerRoutes from "./containers/Manager/ManagerRoutes/ManagerRoutes";

export default function BasePage() {
  const dispatch = useDispatch();
  const userRole = useSelector(userRoleSelector);
  useEffect(() => {
    dispatch(startGetManagers());
    dispatch(startGetClients());
  }, []);
  return (
    <>
      <Switch>
        <Route exact path={["/", "/auth/login"]}>
          <Redirect to="/dashboard" />
        </Route>
        {userRole === "admin" && <AdminRoutes />}
        {userRole === "manager" && <ManagerRoutes />}
        <Route path="/">
          <Redirect to="/error/error-v1" />
        </Route>
      </Switch>
    </>
  );
}
