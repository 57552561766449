import React from "react";
import PropTypes from "prop-types";

const ClientListItemProgress = ({ progress, isCompleted }) => {
  return (
    <div className="d-flex mb-5 align-items-center">
      <span className="d-block font-weight-bold mr-5">
        {isCompleted ? "Completed" : "In Progress"}
      </span>
      <div className="d-flex flex-row-fluid align-items-center">
        <div className="progress progress-xs mt-2 mb-2 w-100">
          <div
            className={`progress-bar ${
              isCompleted ? "bg-primary" : "bg-warning"
            }`}
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <span className="ml-3 font-weight-bolder">{progress}</span>
      </div>
    </div>
  );
};

ClientListItemProgress.defaultProps = {
  isCompleted: false,
  progress: 0,
};
ClientListItemProgress.propTypes = {
  isCompleted: PropTypes.bool,
  progress: PropTypes.number,
};

export default ClientListItemProgress;
