import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ProductHuntAPI from "../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";
import { camelizeKeys } from "../../../API/Api-utils";

import AssignedProducts from "./AssignedProducts/AssignedProducts";

const ProductHuntProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const onLoadUser = () => {
    if (!userId) {
      ProductHuntAPI.getMyProfile()
        .then((res) => {
          setUser(camelizeKeys(res.data.data.user));
        })
        .catch(() => toastr.error("Could Not Get Product Hunt Profile"));
    } else {
      ProductHuntAPI.getUser(25)
        .then((res) => setUser(camelizeKeys(res.data.data.user)))
        .catch(() => toastr.error("Could Not Get Product Hunt User"));
    }
  };
  const { name, email, assignedProjects } = user || {};
  useEffect(() => {
    onLoadUser();
  }, []);
  return (
    <div>
      {user && (
        <div className="row px-5 position-relative">
          <div className="col-4 bg-white p-5" style={{ borderRadius: "6px" }}>
            <h1>{name}</h1>
            <h3 className="mt-2">
              <span>
                <i className="fa fa-envelope text-primary mr-3" />
              </span>
              {email}
            </h3>
          </div>
          <h1 className="col-12 mt-5 p-5">UpVotes</h1>
          <AssignedProducts
            setUser={setUser}
            assignedProjects={assignedProjects}
          />
        </div>
      )}
      <div />
    </div>
  );
};

export default ProductHuntProfile;
