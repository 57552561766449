import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ProductHuntWizardHeader from "./ProductHuntWizardHeader/ProductHuntWizardHeader";
import ProductHuntWizardBody from "./ProductHuntWizardBody/ProductHuntWizardBody";
import PHContext from "../../../../contexts/ProductHuntWizardContext";
import ProductHuntNavigation from "../ProductHuntNavigation/ProductHuntNavigation";
import ProductHuntAPI from "../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const CreateProductHuntWizard = () => {
  const { push } = useHistory();
  const [step, setStep] = useState(0);
  const [productHuntForm, setProductHuntForm] = useState({
    projectImage: "",
    productHuntLink: "",
    projectName: "",
    projectDescription: "",
    assignKind: 2,
  });
  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const previousStep = () => setStep((prevStep) => prevStep - 1);
  const submitPH = () => {
    ProductHuntAPI.createProject(productHuntForm).then((res) => {
      push("/producthunt");
      toastr.success("Product Created");
    });
  };
  return (
    <>
      <ProductHuntNavigation />
      <div className="card card-custom">
        <div className="card-body p-0">
          <div className="wizard wizard-1">
            <PHContext.Provider
              value={{
                step,
                setStep,
                productHuntForm,
                setProductHuntForm,
                nextStep,
                previousStep,
                submitPH,
              }}
            >
              <ProductHuntWizardHeader />
              <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div className="col-xl-12 col-xxl-7">
                  <ProductHuntWizardBody />
                </div>
              </div>
            </PHContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProductHuntWizard;
