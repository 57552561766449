import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clientSelector } from "../../../../../../redux/General-redux/General-selectors";

const CampaignProgressChart = ({ goals }) => {
  const { username } = useParams();
  const labels = Object.keys(goals).map((goalName) => goalName);
  const series = Object.values(goals).map(({ completed, goalTarget }) => {
    if (goalTarget === 0) return 0;
    return Math.floor((completed / goalTarget) * 100);
  });
  const { progress } = useSelector(clientSelector(username));
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "15px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Overview",
            formatter() {
              // Config contains the series, get the AVG From there.
              return progress;
            },
          },
        },
      },
    },
    colors: ["#8950FC", "#FF5700", "#FF0000", "#1DA1F2"],
    labels,
  };
  return (
    <div className="mixed-chart">
      <Chart type="radialBar" series={series} options={options} />
    </div>
  );
};

export default CampaignProgressChart;
