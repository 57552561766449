import React from "react";
import { Route } from "react-router-dom";
import ProductHuntProfile from "../../../components/manager-components/ProductHunt/ProductHuntProfile";

const ProductHuntManager = () => {
  return (
    <>
      <Route exact path="/producthunt">
        <ProductHuntProfile />
      </Route>
    </>
  );
};

export default ProductHuntManager;
