/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/containers/Auth/Manager-pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { isAuthorizedSelector } from "./containers/Auth/auth-redux/auth-selectors";
import { autoLoginStart } from "./containers/Auth/auth-redux/auth-actions";
import AuthPage from "./containers/Auth/pages/AuthPage";
import { getToken } from "./StorageAPI/StorageAPI";

function Routes() {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(autoLoginStart());
  }, []);
  const token = getToken();
  return (
    <Switch>
      {!isAuthorized && !token && (
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/">
            <Redirect to="/auth/login" />
          </Route>
        </Switch>
      )}
      {isAuthorized && (
        <Route path="/">
          <Layout>
            <BasePage />
          </Layout>
        </Route>
      )}
    </Switch>
  );
}

export default Routes;
