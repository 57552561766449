/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../../../../app/containers/Auth/auth-redux/auth-selectors";

export function QuickUser() {
  const user = useSelector(userSelector);
  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-content pr-5 mr-n5">
        {user.userQuickJSX()}
        <div className="separator separator-dashed mt-8 mb-5" />
      </div>
    </div>
  );
}
