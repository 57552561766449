import { createSelector } from "reselect";

export const managersSelector = (state) => state.managerGeneral.managers;
export const clientsSelector = (state) => state.managerGeneral.clients;
export const loadingStatesSelector = (state) =>
  state.managerGeneral.loadingStates;

export const campaignGoalsSelector = (username) => {
  return createSelector(clientsSelector, (clients) => {
    if (!clients.length) return null;
    return clients.find((client) => client.username === username).goalsMapped;
  });
};

export const clientSelector = (username) => {
  return createSelector(clientsSelector, (clients) => {
    return clients.find((client) => client.username === username);
  });
};

export const isClientsLoadingSelector = createSelector(
  loadingStatesSelector,
  ({ getClients }) => getClients
);
