import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CampaignProgressChart from "./campaignProgressChart";
import CardHeader from "../../../../UI-components/Card/CardHeader";
import CampaignProgressLabel from "./campaignProgressLabel";
import { campaignGoalsSelector } from "../../../../../../redux/General-redux/General-selectors";

const ClientCampaignProgress = () => {
  const { username } = useParams();
  const goals = useSelector(campaignGoalsSelector(username));
  if (!goals) return <div />;
  return (
    <div className="col-sm-4 col-lg-4">
      <div className="card card-custom card-stretch gutter-b">
        <CardHeader title="Campaign Progress" />
        <div className="card-body d-flex flex-column">
          <CampaignProgressChart goals={goals} />
          <div
            className="d-flex flex-column justify-content-around h-100"
            style={{ minHeight: "150px" }}
          >
            {Object.entries(goals).map(([name, { completed, goalTarget }]) => (
              <CampaignProgressLabel
                key={name}
                name={name}
                completed={completed}
                goalTarget={goalTarget}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCampaignProgress;
