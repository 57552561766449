import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import moment from "moment";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isCallsLoadingSelector } from "../../Management-redux/Management-selectors";
import {
  startCreateCall,
  startUpdateCall,
} from "../../Management-redux/Management-actions";
import { priorityToString } from "../../../../../utils/models-utils";
import CustomDatePicker from "../../../../UI-components/Formik/DatePicker";
import CustomSelect from "../../../../UI-components/Formik/Select";
import CustomTextArea from "../../../../UI-components/Formik/TextArea";
import CallModel from "../../../../../DataModels/CallModel";

const ManageCallModal = ({ modalStatus, onHide, managedCall }) => {
  const dispatch = useDispatch();
  const { username = "" } = useParams();
  const isLoading = useSelector(isCallsLoadingSelector);
  const prioOptions = [
    { value: "1", label: "Low" },
    { value: "2", label: "Medium" },
    { value: "3", label: "High" },
  ];
  const callSchema = Yup.object().shape({
    date: Yup.string().required("This Field is Required"),
    isCallSummary: Yup.boolean(),
    priority: Yup.string().required("This Field Is Required"),
    summaryText: Yup.string().when("isCallSummary", {
      is: true,
      then: (fieldSchema) => fieldSchema.required("This Is Required"),
    }),
  });
  const handleSubmit = (call) => {
    const { isCallSummary, priority, summaryText, date } = call;
    const status = isCallSummary ? 2 : 1;
    const callForm = {
      status,
      priority,
      notes: summaryText,
      call_date_at: moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      users: [],
    };
    if (managedCall) {
      return dispatch(startUpdateCall(callForm, managedCall.id, onHide));
    }
    return dispatch(startCreateCall(callForm, onHide, username));
  };
  const setFormInitialValues = () => {
    if (managedCall) {
      const { callDateAt, notes, priority, status } = managedCall;
      const isCallSummary = status === 2;
      return {
        date: moment(callDateAt).format("DD-MM-YYYY"),
        priority,
        isCallSummary: !!isCallSummary,
        summaryText: notes,
      };
    }
    return {
      date: moment().format("DD-MM-YYYY"),
      priority: 1,
      isCallSummary: true,
      summaryText: "",
    };
  };
  const defaultPriority = () => {
    if (managedCall) {
      return {
        label: priorityToString(managedCall.priority),
        value: managedCall.priority,
      };
    }
    return {
      label: "Low",
      value: 1,
    };
  };
  return (
    <Modal show={modalStatus} onHide={onHide}>
      <Modal.Header>
        <h3 className="modal-title">Manage Call</h3>
      </Modal.Header>
      <Formik
        initialValues={setFormInitialValues()}
        validationSchema={callSchema}
        onSubmit={(e) => handleSubmit(e)}
      >
        {({ submitForm, values }) => (
          <>
            <Modal.Body>
              <div className="form-group">
                <label className="radio">
                  <Field type="checkbox" name="isCallSummary" />
                  <span className="mr-2" />
                  Call Summary
                </label>
              </div>
              <Field name="date" component={CustomDatePicker} />
              <Field
                defaultValue={defaultPriority()}
                name="priority"
                component={CustomSelect}
                options={prioOptions}
                label="Call Priority"
              />
              {values.isCallSummary && (
                <Field component={CustomTextArea} name="summaryText" />
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                type="button"
                onClick={onHide}
              >
                CANCEL
              </button>
              <button
                style={{ minWidth: "100px" }}
                className="btn btn-primary"
                type="submit"
                onClick={submitForm}
              >
                SUBMIT
                {isLoading && <span className="ml-1 spinner spinner-white" />}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
ManageCallModal.defaultProps = {
  managedCall: PropTypes.object,
};
ManageCallModal.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  managedCall: PropTypes.instanceOf(CallModel),
};

export default ManageCallModal;
