import moment from "moment";
import axiosInstance from "../API-config";

class GeneralAPI {
  static uploadImage(image) {
    const form = new FormData();
    form.append("file", image);
    form.append("file_type", "png");
    return axiosInstance.post("/v2/photos/upload", form);
  }

  static getManagers() {
    return axiosInstance.get("v2/managers");
  }

  static getClients() {
    return axiosInstance.post("/clients/search", {
      active: true,
      limit: 12,
      name: "",
      niches: [],
      offset: 0,
      campaign: moment().format("YYYY-MM-01"),
      advanced: true,
    });
  }
}

export default GeneralAPI;
