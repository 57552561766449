import React, { useContext } from "react";
import PHContext from "../../../../../contexts/ProductHuntWizardContext";

const ProductHuntWizardHeader = () => {
  const { step } = useContext(PHContext);
  const getDataWizardStep = (wizardStep) => {
    if (step === wizardStep) return "current";
    if (step > wizardStep) return "done";
    return "progress";
  };
  return (
    <div className="wizard-nav border-bottom">
      <div className="wizard-steps p-8 p-lg-10">
        <div className="wizard-step" data-wizard-state={getDataWizardStep(0)}>
          <div className="wizard-label">
            <i className="fa fa-address-card wizard-icon" />
            <h3 className="wizard-title">Project Details</h3>
          </div>
          <i className="fa fa-arrow-right svg-icon svg-icon-xl wizard-arrow" />
        </div>
        <div className="wizard-step" data-wizard-state={getDataWizardStep(1)}>
          <div className="wizard-label">
            <i className="fa fa-users wizard-icon" />
            <h3 className="wizard-title">Assign Users</h3>
          </div>
          <i className="fa fa-arrow-right svg-icon svg-icon-xl wizard-arrow" />
        </div>
        <div className="wizard-step" data-wizard-state={getDataWizardStep(2)}>
          <div className="wizard-label">
            <i className="fab fa-battle-net wizard-icon" />
            <h3 className="wizard-title">Review & Submit</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHuntWizardHeader;
