import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ProductHuntNavigation from "../ProductHuntNavigation/ProductHuntNavigation";
import FormSubmitError from "../../../UI-components/Errors/FormSubmitError";
import CardOverlayLoading from "../../../UI-components/Card/CardOverlayLoading";
import { ErrorMessage } from "@hookform/error-message";
import ProductHuntAPI from "../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const ProductHuntCreateUser = () => {
  const { register, errors, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = (userForm) => {
    setIsLoading(true);
    setErrorMessage("");
    ProductHuntAPI.createUser(userForm)
      .then((res) => {
        if (res.data.status === "error") {
          throw Error(res.data.message);
        }
        reset();
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <ProductHuntNavigation />
      <h1 className="mb-10">Create New ProductHunt User</h1>
      <form className="bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
        <CardOverlayLoading isLoading={isLoading} />
        <FormSubmitError errorMessage={errorMessage} />
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input
            className="form-control"
            name="name"
            id="name"
            type="text"
            ref={register({ required: true })}
          />
          <ErrorMessage name="name" errors={errors} message="Required" />
        </div>
        <div className="form-group">
          <label htmlFor="name">Email</label>
          <input
            className="form-control"
            name="email"
            id="email"
            type="email"
            ref={register({ required: true })}
          />
          <ErrorMessage name="email" errors={errors} message="Required" />
        </div>
        <hr />
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ProductHuntCreateUser;
