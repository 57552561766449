import React from "react";
import { useHistory } from "react-router-dom";

const ProductHuntNavigation = () => {
  const { push } = useHistory();
  return (
    <div
      className="d-flex justify-content-between mb-5 bg-white py-3 px-5"
      style={{ borderRadius: "6px" }}
    >
      <div>
        <button
          className="btn btn-light-success mr-2"
          type="button"
          onClick={() => push("/producthunt")}
        >
          Projects
        </button>
        <button
          className="btn btn-light-success mr-2"
          type="button"
          onClick={() => push("/producthunt/users")}
        >
          Users
        </button>
      </div>
      <div>
        <button
          className="btn btn-primary mr-2"
          type="button"
          onClick={() => push("/producthunt/user/create")}
        >
          New User
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => push("/producthunt/create")}
        >
          New Project
        </button>
      </div>
    </div>
  );
};

export default ProductHuntNavigation;
