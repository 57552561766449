import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker/es";
import moment from "moment";
import { inputValidationState } from "./Formik-utils/Formik-utils";

const CustomDatePicker = ({ field, form }) => {
  const { errors } = form;
  return (
    <div className="form-group">
      <label className="d-block" htmlFor={field.name}>
        Date
      </label>
      <DatePicker
        className={[inputValidationState(true, errors.date)]}
        name={field.name}
        value={field.value}
        onChange={(value) =>
          form.setFieldValue("date", moment(value).format("DD-MM-YYYY"))
        }
        onBlur={field.onBlur}
      />
      {errors.date && (
        <div className="invalid-feedback">
          Shucks, check the formatting of that and try again.
        </div>
      )}
    </div>
  );
};

CustomDatePicker.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
};

export default CustomDatePicker;
