import React from "react";

const ModalFooter = ({ isLoading, onHide }) => {
  return (
    <>
      <button className="btn btn-default" type="button" onClick={onHide}>
        CANCEL
      </button>
      <button
        style={{ minWidth: "100px" }}
        className="btn btn-primary px-5"
        type="submit"
      >
        SUBMIT
        {isLoading && <span className="ml-1 spinner spinner-white" />}
      </button>
    </>
  );
};

export default ModalFooter;
