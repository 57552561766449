import axios from "../API-config";

class ManagementAPI {
  static getCalls(username) {
    return axios.get(`v2/calls/client/${username}/`);
  }

  static getAllCalls() {
    return axios.get("v2/calls/me");
  }

  static createCall(call, username) {
    return axios.post(`v2/calls/client/${username}`, { form: call });
  }

  static updateCall(updatedCall, callId) {
    return axios.put(`v2/call/${callId}`, { form: updatedCall });
  }

  static deleteCall(callId) {
    return axios.delete(`v2/call/${callId}`);
  }

  static getTasks(taskTarget) {
    return axios.get(`v2/tasks/client/${taskTarget}`);
  }

  static getAllTasks() {
    return axios.get(`v2/tasks/me`);
  }

  static createTask(task, taskTarget) {
    if (!taskTarget || taskTarget === "general") {
      return axios.post(`v2/tasks/internal`, task);
    }
    return axios.post(`v2/tasks/client/${taskTarget}`, task);
  }

  static updateTask(updatedTask, taskId) {
    return axios.put(`v2/task/${taskId}`, { form: updatedTask });
  }

  static deleteTask(taskId) {
    return axios.delete(`v2/task/${taskId}`);
  }
}

export default ManagementAPI;
