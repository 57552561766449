export const storeUser = (user, token, roles) => {
  const userStorage = {
    user,
    token,
    roles,
  };
  localStorage.setItem("user", JSON.stringify(userStorage));
};
export const clearStorageItem = (item) => {
  localStorage.removeItem(item);
};
export const getStorageUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
export const getToken = () => {
  const storageUser = getStorageUser();
  if (!storageUser) return "";
  const { token } = storageUser.token;
  return token;
};
