import { takeEvery, call, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { camelizeKeys } from "../../../../API/Api-utils";
import * as types from "./Management-actionTypes";
import * as actions from "./Management-actions";

import CallModel from "../../../../DataModels/CallModel";
import ManagementAPI from "../../../../API/Management-API/ManagementAPI";
import { startGetCalls } from "./Management-actions";
import TaskModel from "../../../../DataModels/TaskModel";

function* getTasksSaga({ taskTarget, fetchAll }) {
  try {
    let response;
    if (!fetchAll && taskTarget) {
      response = yield call(ManagementAPI.getTasks, taskTarget);
    }
    if (fetchAll) {
      response = yield call(ManagementAPI.getAllTasks);
    }
    const { clientTasks, createdByUser } = yield camelizeKeys(
      response.data.data
    );
    const tasksMap = {};
    const allTasks = [];
    clientTasks.forEach((clientTask) => {
      const { id } = clientTask;
      if (!tasksMap[id]) {
        tasksMap[id] = id;
        allTasks.push(clientTask);
      }
    });
    if (createdByUser) {
      createdByUser.forEach((clientTask) => {
        const { id } = clientTask;
        if (!tasksMap[id]) {
          tasksMap[id] = id;
          allTasks.push(clientTask);
        }
      });
    }
    const tasksModels = yield allTasks
      .map((task) => new TaskModel(task))
      .sort((a, b) => a.id - b.id);
    yield put(actions.successGetTasks(tasksModels));
  } catch (error) {
    yield put(actions.failGetTasks());
  }
}
function* createTaskSaga({ task, taskTarget, onHide, fetchAll }) {
  try {
    yield call(ManagementAPI.createTask, task, taskTarget);
    yield put(actions.successCreateTask());
    yield put(actions.startGetTasks(taskTarget, fetchAll));
    yield call(onHide);
  } catch (error) {
    yield put(actions.failCreateTask());
  }
}
function* deleteTaskSaga({ taskId, taskTarget }) {
  try {
    yield ManagementAPI.deleteTask(taskId);
    yield put(actions.successDeleteTask());
    yield put(actions.startGetTasks(taskTarget, !taskTarget));
    yield call(toastr.success, "Task Deleted");
  } catch (error) {
    yield call(toastr.error, "Task Delete Failed");
    yield put(actions.failDeleteTask());
  }
}
function* updateTaskSaga({ updatedTask, taskId, onHide, taskTarget }) {
  try {
    yield call(ManagementAPI.updateTask, updatedTask, taskId);
    yield put(actions.successUpdateTask());
    yield put(actions.startGetTasks(taskTarget, !taskTarget));
    yield call(onHide);
  } catch (error) {
    yield call(toastr.error, "Updating Task Failed");
    yield put(actions.failUpdateTask());
  }
}

function* getCallsSaga({ username }) {
  let response;
  try {
    if (username) {
      response = yield call(ManagementAPI.getCalls, username);
    } else {
      response = yield call(ManagementAPI.getAllCalls);
    }
    const responseFormatted = yield camelizeKeys(response.data.data);
    const { clientCalls } = responseFormatted;
    const callsModels = yield clientCalls
      .map((task) => new CallModel(task))
      .sort((a, b) => a.id - b.id);
    yield put(actions.successGetCalls(callsModels));
  } catch (error) {
    yield put(actions.failGetCalls());
  }
}
function* createCallSaga({ newCall, username, onHide }) {
  try {
    yield call(ManagementAPI.createCall, newCall, username);
    yield put(actions.successCreateCall());
    yield call(getCallsSaga, { username });
    yield call(onHide);
  } catch (error) {
    yield put(actions.failCreateCall());
  }
}
function* deleteCallSaga({ callId, username }) {
  try {
    yield ManagementAPI.deleteCall(callId);
    yield put(actions.successDeleteCall());
    yield call(getCallsSaga, { username });
  } catch (error) {
    yield put(actions.failDeleteCall());
  }
}
function* updateCallSaga({ updatedCall, callId, onHide, username }) {
  try {
    yield call(ManagementAPI.updateCall, updatedCall, callId);
    yield put(actions.successUpdateCall());
    yield put(startGetCalls(username));
    yield call(onHide);
  } catch (error) {
    yield put(actions.failUpdateCall());
  }
}

export default function* watchManagerManagement() {
  yield takeEvery(types.START_GET_TASKS, getTasksSaga);
  yield takeEvery(types.START_CREATE_TASK, createTaskSaga);
  yield takeEvery(types.START_UPDATE_TASK, updateTaskSaga);
  yield takeEvery(types.START_DELETE_TASK, deleteTaskSaga);
  yield takeEvery(types.START_GET_CALLS, getCallsSaga);
  yield takeEvery(types.START_CREATE_CALL, createCallSaga);
  yield takeEvery(types.START_UPDATE_CALL, updateCallSaga);
  yield takeEvery(types.START_DELETE_CALL, deleteCallSaga);
}
