import { createContext } from "react";

const PHContext = createContext({
  step: 0,
  nextStep: () => {},
  previousStep: () => {},
  productHuntForm: {},
  setProductHuntForm: () => {},
  submitPH: () => {},
});

export default PHContext;
