import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CardOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  cursor: progress;
  background-color: #afafaf8f;
  top: 0;
  right: 0;
`;

const CardOverlayLoading = ({ isLoading }) => {
  if (!isLoading) return <div />;
  return (
    <CardOverlayContainer>
      <span
        style={{
          width: "130px",
          backgroundColor: "white",
          borderRadius: "6px",
          padding: "10px",
        }}
      >
        Please wait...
        <span className="ml-2 spinner spinner-primary" />
      </span>
    </CardOverlayContainer>
  );
};

CardOverlayLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default CardOverlayLoading;
