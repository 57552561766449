import axios from "../API-config";

class AuthApi {
  static login(loginForm) {
    return axios.post("v2/auth/login", loginForm);
  }

  static logout() {
    return axios.post("API_LOGOUT");
  }

  static signUp() {
    return axios.post("API_SIGN_UP");
  }
}

export default AuthApi;
