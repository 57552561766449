import React from "react";
import { Route, Switch } from "react-router-dom";
import ClientCampaignOverview from "../../../components/shared-components/Clients/ClientCampaignOverview/ClientCampaignOverview";
import ClientDashboard from "../../../components/shared-components/Clients/ClientDashboard/ClientDashboard";
import ClientsList from "../../../components/shared-components/Clients/ClientsList/ClientsList";

const ClientsManager = () => {
  return (
    <>
      <Switch>
        <Route exact path="/clients">
          <ClientsList />
        </Route>
        <Route exact path="/clients/:username/campaign">
          <ClientCampaignOverview />
        </Route>
        <Route exact path="/clients/:username/dashboard">
          <ClientDashboard />
        </Route>
      </Switch>
    </>
  );
};

export default ClientsManager;
