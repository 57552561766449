import React from "react";
import PropTypes from "prop-types";
import { numToColor } from "../../../../../utils/models-utils";

const TableButtons = ({
  handleDelete,
  handleUpdate,
  handleUpdateModal,
  status = 0,
}) => {
  const statusColor = numToColor(status);
  let statusIcon = <i className="far fa-circle text-warning" />;
  if (status === 2) {
    statusIcon = <i className="fa fa-check-circle text-success" />;
  }
  return (
    <div className="d-flex">
      {handleDelete && (
        <button
          className="btn btn-light btn-hover-danger btn-icon mx-1"
          type="button"
          onClick={() => handleDelete()}
        >
          <i className="fa fa-trash-alt text-danger" />
        </button>
      )}
      {handleUpdate && (
        <button
          className={`btn btn-light btn-hover-${statusColor}  btn-icon mx-1`}
          type="button"
          onClick={() => handleUpdate()}
        >
          {statusIcon}
        </button>
      )}
      {handleUpdateModal && (
        <button
          className="btn btn-light-primary btn-icon mx-1"
          type="button"
          onClick={handleUpdateModal}
        >
          <i className="fa fa-pencil-alt" />
        </button>
      )}
    </div>
  );
};

TableButtons.propTypes = {
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleUpdateModal: PropTypes.func,
  status: PropTypes.number,
};

export default TableButtons;
