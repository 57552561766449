export const START_GET_TASKS = "START_GET_TASKS";
export const START_CREATE_TASK = "START_CREATE_TASK";
export const START_DELETE_TASK = "START_DELETE_TASK";
export const START_UPDATE_TASK = "START_UPDATE_TASK";
export const START_GET_CALLS = "START_GET_CALLS";
export const START_CREATE_CALL = "START_CREATE_CALL";
export const START_DELETE_CALL = "START_DELETE_CALL";
export const START_UPDATE_CALL = "START_UPDATE_CALL";

export const SUCCESS_GET_TASKS = "SUCCESS_GET_TASKS";
export const SUCCESS_CREATE_TASK = "SUCCESS_CREATE_TASK";
export const SUCCESS_DELETE_TASK = "SUCCESS_DELETE_TASK";
export const SUCCESS_UPDATE_TASK = "SUCCESS_UPDATE_TASK";
export const SUCCESS_GET_CALLS = "SUCCESS_GET_CALLS";
export const SUCCESS_CREATE_CALL = "SUCCESS_CREATE_CALL";
export const SUCCESS_DELETE_CALL = "SUCCESS_DELETE_CALL";
export const SUCCESS_UPDATE_CALL = "SUCCESS_UPDATE_CALL";

export const FAIL_GET_TASKS = "FAIL_GET_TASKS";
export const FAIL_CREATE_TASK = "FAIL_CREATE_TASK";
export const FAIL_DELETE_TASK = "FAIL_DELETE_TASK";
export const FAIL_UPDATE_TASK = "FAIL_UPDATE_TASK";
export const FAIL_UPDATE_CALL = "FAIL_UPDATE_CALL";
export const FAIL_GET_CALLS = "FAIL_GET_CALLS";
export const FAIL_CREATE_CALL = "FAIL_CREATE_CALL";
export const FAIL_DELETE_CALL = "FAIL_DELETE_CALL";
