import React from "react";
import moment from "moment";
import { textFilter } from "react-bootstrap-table2-filter";
import AvatarImage from "../../../../UI-components/Tables/AvatarImage";
import UsersImageGroup from "../../../../UI-components/UsersImageGroup/UsersImageGroup";
import InboundJunctionLogo from "../../../../../../assets/images/Inboundjunction-logo.jpeg";

const setTasksColumns = (
  handleUpdate,
  handleDelete,
  handleUpdateModal,
  setFilters,
  username
) => [
  {
    dataField: "id",
    text: "ID",
    hidden: true,
  },
  {
    dataField: "client",
    text: "Client",
    hidden: !!username,
    formatter: (col) => {
      if (username) {
        return null;
      }
      if (!col) {
        return <AvatarImage image={InboundJunctionLogo} username="IJ" />;
      }
      return <AvatarImage image={col.image} username={col.username} />;
    },
    headerStyle: () => ({
      width: "150px",
    }),
    filterValue: (cell) => (cell ? cell.username : "IJ"),
    filter: textFilter({
      style: { display: "none" },
      getFilter: (filter) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          clientFilter: filter,
        }));
      },
    }),
  },
  {
    dataField: "manager",
    text: "Origin",
    formatter: (col) => <AvatarImage image={col.image} />,
    headerStyle: () => ({
      width: "100px",
    }),
  },
  {
    dataField: "title",
    text: "Title",
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (col, row) => row.statusLabelJSX(),
  },
  {
    dataField: "priority",
    text: "Priority",
    formatter: (col, row) => row.priorityLabelJSX(),
    filter: textFilter({
      style: { display: "none" },
      getFilter: (filter) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          priorityFilter: filter,
        }));
      },
    }),
  },
  {
    dataField: "taskDateAt",
    text: "Time Frame",
    formatter: (col) => moment(col).format("DD-MM-YYYY"),
  },
  {
    dataField: "users",
    text: "Responsibility",
    formatter: (col) => <UsersImageGroup users={col} />,
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (col, row) =>
      row.tableButtonsJSX(handleUpdate, handleDelete, handleUpdateModal),
  },
];

export default setTasksColumns;
