import { takeEvery, call, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import * as types from "./General-actionTypes";
import { camelizeKeys } from "../../app/API/Api-utils";
import {
  failGetClients,
  failGetManagers,
  successGetClients,
  successGetManagers,
} from "./General-actions";
import GeneralAPI from "../../app/API/General-API/General-API";
import Client from "../../app/DataModels/Client";

function* getClientsSaga() {
  try {
    const response = yield call(GeneralAPI.getClients);
    const { clients } = yield camelizeKeys(response.data.data);
    const clientsFormatted = yield clients.map((client) => new Client(client));
    yield put(successGetClients(clientsFormatted));
  } catch (error) {
    yield call(toastr.error, "Fetching Clients Failed");
    yield put(failGetClients());
  }
}

function* getManagersSaga() {
  try {
    const response = yield call(GeneralAPI.getManagers);
    const { managers } = yield camelizeKeys(response.data.data);
    yield put(successGetManagers(managers));
  } catch (error) {
    yield put(failGetManagers());
  }
}

export default function* watchManagerGeneral() {
  yield takeEvery(types.START_GET_MANAGERS, getManagersSaga);
  yield takeEvery(types.START_GET_CLIENTS, getClientsSaga);
}
