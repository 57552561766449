import React from "react";
import { domainName } from "../utils/general-utils";

class Client {
  constructor(client) {
    const {
      brief,
      company,
      email,
      goals,
      goalsCompleted,
      id,
      image,
      managers,
      name,
      niche,
      report,
      username,
    } = client;
    this.company = company;
    this.email = email;
    this.goals = goals;
    this.goalsCompleted = goalsCompleted;
    this.goalsMapped = {
      articles: {
        completed: 0,
        goalTarget: 0,
      },
    };
    this.id = id;
    this.image = image;
    this.managers = managers;
    this.name = name;
    this.niche = niche;
    this.report = report;
    this.username = username;
    this.isCompleted = false;
    this.progress = 10;
    this.isPaid = false;
    this.paymentString = "";
    this.brief = brief;
    this.getPaymentStatusString();
    this.mapGoalsProgress();
  }

  mapGoalsProgress() {
    let totalGoals = 0;
    Object.keys(this.goals).forEach((goalKey) => {
      totalGoals += this.goals[goalKey];
      if (goalKey !== "articlesConnected") {
        this.goalsMapped[goalKey] = {
          completed: 0,
          goalTarget: this.goals[goalKey],
        };
      }
    });
    if (totalGoals === 0 || !this.goalsCompleted) {
      this.isCompleted = false;
      this.progress = 0;
      return;
    }
    this.goalsCompleted.forEach((goal) => {
      const goalDomain = domainName(goal);
      if (!this.goalsMapped[goalDomain]) {
        this.goalsMapped.articles.completed += 1;
      } else {
        this.goalsMapped[goalDomain].completed += 1;
      }
    });
    this.progress = Math.floor((this.goalsCompleted.length / totalGoals) * 100);
    if (this.progress === 100) {
      this.isCompleted = true;
    }
  }

  getPaymentStatusString() {
    const { invoice, prepayment, details } = this.report;
    const { totalIncomes } = details;
    if (!invoice || !prepayment) {
      this.isPaid = 3;
      this.paymentString = "No Payment Info, Contact Admin";
      return;
    }
    if (invoice && invoice.status === "Invoice Paid") {
      this.isPaid = 2;
      this.paymentString = "Paid";
      return;
    }
    if (invoice && invoice.status === "Invoice Created") {
      this.isPaid = 4;
      this.paymentString = "Invoice Created";
      return;
    }
    if (
      prepayment.status === "Invoice Paid" &&
      prepayment.paymentInt === totalIncomes
    ) {
      this.isPaid = 2;
      this.paymentString = "Paid";
      return;
    }
    if (prepayment.status === "Invoice Paid") {
      this.isPaid = 1;
      this.paymentString = "Partially Paid";
    }
  }

  paymentLabelJSX() {
    const btnClass = "btn btn-sm font-weight-bold btn-upper btn-text";
    let btnColor;
    switch (this.isPaid) {
      case 0:
        btnColor = "btn-light-warning";
        break;
      case 1:
        btnColor = `btn-light-warning`;
        break;
      case 2:
        btnColor = `btn-light-success`;
        break;
      case 3:
        btnColor = "btn-danger";
        break;
      case 4:
        btnColor = "btn-light-primary";
        break;
      default:
        btnColor = `btn btn-light-danger`;
    }
    return (
      // Show Pulse If invoice && prepayment is not set!
      // Else show normal label
      <div className={`${btnClass} ${btnColor}`}>{this.paymentString}</div>
    );
  }
}

export default Client;
