import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import setCallsColumns from "./CallsTable-columns";
import CardOverlayLoading from "../../../../UI-components/Card/CardOverlayLoading";
import CardHeader from "../../../../UI-components/Card/CardHeader";
import paginationOptions from "../../../../UI-components/Tables/Pagination";
import TableToolbar from "../sharedComponents/TableToolbar";
import {
  startDeleteCall,
  startGetCalls,
  startUpdateCall,
} from "../../Management-redux/Management-actions";
import {
  callsSelector,
  isCallsLoadingSelector,
} from "../../Management-redux/Management-selectors";
import AvatarImage from "../../../../UI-components/Tables/AvatarImage";
import ManageCallModal from "../modals/ManageCallModal";

const CallsTable = () => {
  const { username = "" } = useParams();
  const [modalStatus, setModalStatus] = useState(false);
  const [managedCall, setManagedCall] = useState(null);
  const dispatch = useDispatch();
  const calls = useSelector(callsSelector);
  const handleShowModal = (call) => {
    setModalStatus(true);
    setManagedCall(call);
  };
  const handleShowModalNew = () => {
    setModalStatus(true);
  };
  const handleHideModal = () => setModalStatus(false);
  const handleUpdate = (callId, updatedCall) =>
    dispatch(startUpdateCall(callId, updatedCall, handleHideModal, username));
  const handleDelete = (callId) => dispatch(startDeleteCall(callId, username));
  const columns = setCallsColumns(handleUpdate, handleDelete, handleShowModal);
  const isLoading = useSelector(isCallsLoadingSelector);
  if (calls[0] && calls[0].client) {
    columns.unshift({
      dataField: "client",
      text: "Client",
      formatter: (col) => (
        <AvatarImage image={col.image} username={col.username} />
      ),
      headerStyle: () => ({
        width: "150px",
      }),
    });
  }
  useEffect(() => {
    dispatch(startGetCalls(username));
  }, []);
  return (
    <div className="col-lg-12 position-relative">
      <div className="card card-custom card-stretch gutter-b">
        <CardOverlayLoading isLoading={isLoading} />
        <CardHeader title="Calls">
          {username && (
            <TableToolbar
              handleModal={handleShowModalNew}
              btnText="Add New Call"
              icon="fa fa-phone-volume"
            />
          )}
        </CardHeader>
        <div
          className="card-body pt-0 pb-3
        datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-subtable datatable-loaded"
        >
          <BootstrapTable
            pagination={paginationFactory(paginationOptions)}
            columns={columns}
            data={calls}
            keyField="id"
            bordered={false}
            bootstrap4
          />
        </div>
      </div>
      <ManageCallModal
        onHide={handleHideModal}
        modalStatus={modalStatus}
        managedCall={managedCall}
      />
    </div>
  );
};

export default CallsTable;
