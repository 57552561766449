import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { APP_URL_DEFAULT } from "../../../../../../API/API-config";
import CardOverlayLoading from "../../../../../UI-components/Card/CardOverlayLoading";
import ProductHuntAPI from "../../../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const AssignedUser = ({ assignedUser, onLoadProduct }) => {
  const {
    user,
    askComment,
    askVote,
    vote: didVote,
    comment: didComment,
    id,
  } = assignedUser;
  const { name, image } = user;
  const [isLoading, setIsLoading] = useState(false);
  const updateProductVote = (actionKind) => {
    setIsLoading(true);
    const voteForm = {
      vote: actionKind === "vote" ? !didVote : didVote,
      comment: actionKind === "comment" ? !didComment : didComment,
    };
    ProductHuntAPI.updateProductVote(id, voteForm)
      .then(() => onLoadProduct(() => setIsLoading(false)))
      .catch(() => {
        setIsLoading(false);
        toastr.error("Failed Updating Vote");
      });
  };
  return (
    <div className="col-4 col-md-6 col-lg-4">
      <div
        className="p-5 bg-white mt-5 d-flex flex-row justify-content-between position-relative align-items-center"
        style={{ borderRadius: "6px" }}
      >
        {isLoading && <CardOverlayLoading isLoading={isLoading} />}
        <div>
          <img
            className="mr-5"
            src={`${APP_URL_DEFAULT}${image || "assets/images/user_guest.png"}`}
            alt="user"
            style={{ width: "60px", borderRadius: "6px" }}
          />
          <span className="mb-2 font-size-lg mt-1 font-weight-bold">
            {name}
          </span>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ height: "fit-content" }}
        >
          {askComment && (
            <button
              onClick={() => updateProductVote("comment")}
              className={`btn btn-outline-${
                (didComment && "primary") || "secondary"
              } mr-3`}
              type="button"
            >
              <i className="fa fa-comment" />
            </button>
          )}
          {askVote && (
            <button
              onClick={() => updateProductVote("vote")}
              className={`btn btn-outline-${
                (didVote && "primary") || "secondary"
              } mr-3`}
              type="button"
            >
              <i className="fa fa-thumbs-up" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignedUser;
