import * as types from "./General-actionTypes";

export const startGetManagers = () => ({
  type: types.START_GET_MANAGERS,
});
export const startGetClients = () => ({
  type: types.START_GET_CLIENTS,
});

export const successGetManagers = (managers) => ({
  type: types.SUCCESS_GET_MANAGERS,
  managers,
});
export const successGetClients = (clients) => ({
  type: types.SUCCESS_GET_CLIENTS,
  clients,
});

export const failGetManagers = () => ({
  type: types.FAIL_GET_MANAGERS,
});
export const failGetClients = () => ({
  type: types.FAIL_GET_CLIENTS,
});
