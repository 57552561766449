import React from "react";
import { Route, Switch } from "react-router-dom";
import ProductHuntProjects from "../../../components/admin-components/ProductHunt/ProductHuntProjects/ProductHuntProjects";
import SingleProductView from "../../../components/admin-components/ProductHunt/SingleProductView/SingleProductView";
import ProductHuntUsers from "../../../components/admin-components/ProductHunt/ProductHuntUsers/ProductHuntUsers";
import ProductHuntCreateUser from "../../../components/admin-components/ProductHunt/ProductHuntCreateUser/ProductHuntCreateUser";
import CreateProductHuntWizard from "../../../components/admin-components/ProductHunt/CreateProductHuntWizard/CreateProductHuntWizard";
import ProductHuntUser from "../../../components/manager-components/ProductHunt/ProductHuntProfile";

const ProductHuntAdmin = () => (
  <>
    <Switch>
      <Route exact path="/producthunt">
        <ProductHuntProjects />
      </Route>
      <Route exact path="/producthunt/create">
        <CreateProductHuntWizard />
      </Route>
      <Route exact path="/producthunt/users/:userId">
        <ProductHuntUser />
      </Route>
      <Route exact path="/producthunt/user/create">
        <ProductHuntCreateUser />
      </Route>
      <Route path="/producthunt/product/:id">
        <SingleProductView />
      </Route>
      <Route path="/producthunt/users">
        <ProductHuntUsers />
      </Route>
    </Switch>
  </>
);

export default ProductHuntAdmin;
