import React from "react";
import PropTypes from "prop-types";

const ColoredLabel = ({ color, labelText }) => {
  return (
    <span className={`label label-lg label-light-${color} label-inline`}>
      {labelText}
    </span>
  );
};

ColoredLabel.defaultProps = {
  color: "success",
};
ColoredLabel.propTypes = {
  color: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
export default ColoredLabel;
