export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const AUTO_LOGIN_START = "AUTO_LOGIN_START";
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS";
export const AUTO_LOGIN_FAIL = "AUTO_LOGIN_FAIL";
