import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toastr } from "react-redux-toastr";
import columns from "./productHuntUsers-columns";
import { camelizeKeys } from "../../../../API/Api-utils";
import paginationOptions from "../../../UI-components/Tables/Pagination";
import ProductHuntNavigation from "../ProductHuntNavigation/ProductHuntNavigation";
import CardOverlayLoading from "../../../UI-components/Card/CardOverlayLoading";
import PHUser from "../../../../DataModels/PHUser";
import ProductHuntUpdateUser from "../ProductHuntUpdateUser/ProductHuntUpdateUser";
import ProductHuntAPI from "../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const ProductHuntUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateModalStatus, setUpdateModalStatus] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const hideUpdateModal = () => setUpdateModalStatus(false);
  const showUpdateModal = (user) => {
    setUpdateModalStatus(true);
    setUserToUpdate(user);
  };
  const onLoadUsers = () => {
    setIsLoading(true);
    ProductHuntAPI.getUsers()
      .then((res) => {
        const usersModels = camelizeKeys(res.data.data.users).map((user) => {
          return new PHUser(user);
        });
        setUsers(usersModels);
      })
      .catch(() => toastr.error("Something Went Wrong Fetching Users"))
      .finally(() => setIsLoading(false));
  };
  const onDelete = (userId) => {
    setIsLoading(true);
    ProductHuntAPI.deleteUser(userId)
      .then(() => {
        toastr.success("ProductHunt User Deleted");
        onLoadUsers();
      })
      .catch(() => toastr.error("Delete User Failed"));
  };
  const onUpdate = (updatedUser) => {
    const { id } = userToUpdate || {};
    ProductHuntAPI.updateUser(updatedUser, id)
      .then(() => onLoadUsers())
      .catch(() => toastr.error("Updating User Failed "))
      .finally(() => {
        setUpdateModalStatus(false);
        setUserToUpdate(null);
      });
  };
  useEffect(() => {
    onLoadUsers();
  }, []);
  return (
    <>
      <ProductHuntNavigation />
      <div className="bg-white p-5 position-relative ">
        <CardOverlayLoading isLoading={isLoading} />
        <BootstrapTable
          pagination={paginationFactory(paginationOptions)}
          keyField="id"
          data={users}
          columns={columns(onDelete, showUpdateModal)}
          bordered={false}
          bootstrap4
        />
      </div>
      <ProductHuntUpdateUser
        user={userToUpdate}
        onHide={hideUpdateModal}
        modalStatus={updateModalStatus}
        onSubmit={onUpdate}
      />
    </>
  );
};

export default ProductHuntUsers;
