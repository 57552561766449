import React, { useState } from "react";
import { APP_URL_DEFAULT } from "../../../../../API/API-config";

import CardOverlayLoading from "../../../../UI-components/Card/CardOverlayLoading";
import ProductHuntAPI from "../../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";
import { camelizeKeys } from "../../../../../API/Api-utils";

const AssignedProduct = ({ assignedProject, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    project,
    askComment,
    askVote,
    vote: didVote,
    comment: didComment,
    id,
  } = assignedProject;
  const { title, description, image, url } = project;
  const updateUserVotes = (updatedVote) => {
    setUser((prevUser) => {
      const { id: updatedVoteId } = updatedVote;
      const { assignedProjects } = prevUser;
      const updatedVotes = assignedProjects.map((vote) => {
        if (updatedVoteId === vote.id) return updatedVote;
        return vote;
      });
      return { ...prevUser, assignedProjects: updatedVotes };
    });
  };
  const updateProductVote = (actionKind) => {
    setIsLoading(true);
    const voteForm = {
      vote: actionKind === "vote" ? !didVote : didVote,
      comment: actionKind === "comment" ? !didComment : didComment,
    };
    ProductHuntAPI.updateProductVote(id, voteForm)
      .then((res) => {
        const { vote } = res.data.data;
        updateUserVotes(camelizeKeys(vote));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="col-md-12 col-lg-6 mt-3">
      <div
        className="p-5 bg-white  position-relative"
        style={{ borderRadius: "6px" }}
      >
        <CardOverlayLoading isLoading={isLoading} />
        <div className="d-flex">
          <img
            src={`${APP_URL_DEFAULT}/${image}`}
            style={{ maxHeight: "50px" }}
            alt="product"
          />
          <a
            className="ml-3 text-primary font-size-h3"
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        </div>
        <p className="mt-3 font-size-lg">{description}</p>
        <hr />
        <div className="d-flex justify-content-center">
          {askComment && (
            <button
              onClick={() => updateProductVote("comment")}
              className={`btn btn-outline-${
                (didComment && "primary") || "secondary"
              } mr-3`}
              type="button"
            >
              <i className="fa fa-comment" />
            </button>
          )}
          {askVote && (
            <button
              onClick={() => updateProductVote("vote")}
              className={`btn btn-outline-${
                (didVote && "primary") || "secondary"
              } mr-3`}
              type="button"
            >
              <i className="fa fa-thumbs-up" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignedProduct;
