import React from "react";
import { forOwn } from "lodash";

import UserQuick from "../../components/UI-components/UserQuick/UserQuick";

class User {
  constructor(user) {
    forOwn(user, (value, key) => {
      this[key] = value;
    });
  }

  userQuickJSX() {
    return <UserQuick user={this} />;
  }
}

export default User;
