import React from "react";
import { useSelector } from "react-redux";
import ClientDetails from "./ClientDetails/ClientDetails";
import ClientCampaignProgress from "./ClientCampaignProgress/clientCampaignProgress";
import TasksTable from "../../Management/Management-components/TasksTable/TasksTable";
import CallsTable from "../../Management/Management-components/CallsTable/CallsTable";
import { clientsSelector } from "../../../../../redux/General-redux/General-selectors";
import PageOverLayLoading from "../../../UI-components/Page/PageOverLayLoading";

const ClientDashboard = () => {
  const clients = useSelector(clientsSelector);
  if (!clients.length) return <PageOverLayLoading isLoading />;
  return (
    <div className="d-flex flex-row">
      <div className="flex-row-fluid ml-lg-12">
        <div className="row">
          <ClientDetails />
          <ClientCampaignProgress />
        </div>
        <div className="row">
          <TasksTable />
          <CallsTable />
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
