import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import { clientSelector } from "../../../../../../redux/General-redux/General-selectors";
import { APP_URL_DEFAULT } from "../../../../../API/API-config";

const ClientDetails = () => {
  const { push } = useHistory();
  const { username } = useParams();
  const client = useSelector(clientSelector(username));
  const { image, email } = client;
  return (
    <div className="col-sm-12 col-lg-8">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body pt-4 d-flex flex-column justify-content-around">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 mr-4 symbol symbol-60 symbol-square">
              <img src={`${APP_URL_DEFAULT}${image}`} alt="client" />
            </div>
            <div>
              <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                {username}
              </span>
              <div className="text-muted">Company Main Niche</div>
            </div>
          </div>
          <div className="pt-8 pb-6">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="font-weight-bold mr-2">Email:</span>
              <span className="text-muted text-hover-primary">
                {email || "Missing Email"}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="font-weight-bold mr-2">Phone:</span>
              <span className="text-muted">Company Phone</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="font-weight-bold mr-2">Location:</span>
              <span className="text-muted">Company Location</span>
            </div>
          </div>

          <div className="pb-6">Company Description Would be here.</div>
          <button
            onClick={() => push(`/clients/${username}/campaign`)}
            className="btn btn-outline-info font-weight-bold py-3 px-6 mb-2 text-center btn-block"
            type="button"
          >
            {`Overview - ${moment().format("MMMM")}`}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClientDetails;
