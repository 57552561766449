/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr/lib/ReduxToastr";
import { I18nProvider } from "../_metronic/i18n";
import { MaterialThemeProvider } from "../_metronic/layout";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import Routes from "./Routes";

export default function App({ store, basename }) {
  return (
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <MaterialThemeProvider>
          <I18nProvider>
            <ReduxToastr />
            <Routes />
          </I18nProvider>
        </MaterialThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}
