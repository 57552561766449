const columns = (onDelete, onUpdate) => [
  { dataField: "id", text: "ID", hidden: true },
  { dataField: "name", text: "Full Name" },
  { dataField: "email", text: "Email" },
  { dataField: "votesCount", text: "Total Votes" },
  { dataField: "commentsCount", text: "Total Comments" },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (col, row) => row.tableButtonsJSX(onDelete, onUpdate),
  },
];

export default columns;
