import React from "react";
import TasksTable from "../../../components/shared-components/Management/Management-components/TasksTable/TasksTable";
import CallsTable from "../../../components/shared-components/Management/Management-components/CallsTable/CallsTable";

const DashboardManager = () => {
  return (
    <>
      <TasksTable />
      <CallsTable />
    </>
  );
};

export default DashboardManager;
