function isValidUrl(string) {
  try {
    // eslint-disable-next-line no-new
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}
export function domainName(url) {
  if (isValidUrl(url)) {
    const domain = new URL(url);
    const { hostname } = domain;
    const hostSplit = hostname.split(".");
    hostSplit.pop();
    if (hostSplit.length > 1) {
      hostSplit.shift();
    }
    return hostSplit.join(".");
  }
  return "";
}
