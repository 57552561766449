import React, { useContext } from "react";
import PHStepDetails from "./ProductHuntWizardBody-steps/PHStepDetails/PHStepDetails";
import PHStepUsers from "./ProductHuntWizardBody-steps/PHStepUsers/PHStepUsers";
import PHContext from "../../../../../contexts/ProductHuntWizardContext";
import PHStepSummary from "./ProductHuntWizardBody-steps/PHStepSummary/PHStepSummary";

const ProductHuntWizardBody = () => {
  const { step } = useContext(PHContext);
  return (
    <>
      <div className="pb-5">
        {step === 0 && <PHStepDetails />}
        {step === 1 && <PHStepUsers />}
        {step === 2 && <PHStepSummary />}
      </div>
    </>
  );
};

export default ProductHuntWizardBody;
