import React from "react";
import styled from "styled-components";

const NavigationIcon = styled.a`
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 500;
  border-radius: 0.42rem;
  color: #7e8299;
  border-radius: 6px;
`;

const customTotal = (from, to, size) => (
  <>
    {size ? (
      <span className="react-bootstrap-table-pagination-total ml-3">
        {`Showing ${from} to ${to} of ${size}`}
      </span>
    ) : (
      <span className="react-bootstrap-table-pagination-total ml-3">
        No Results Found
        <i className="fa fa-sad-cry text-warning ml-1" />
      </span>
    )}
  </>
);

const pageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  const isIcon = typeof page === "object";
  let key = page;
  if (typeof page === "object") {
    key = page.props.className;
  }
  return (
    <li className="page-item" style={{ borderRadius: "6px" }} key={key}>
      <NavigationIcon
        type="button"
        onClick={handleClick}
        className={`btn btn-xs m-1 ${
          active ? "btn-primary" : "btn-hover-primary"
        } ${isIcon ? "btn-light" : ""}`}
      >
        {page}
      </NavigationIcon>
    </li>
  );
};

const paginationOptions = {
  paginationSize: 4,
  pageStartIndex: 0,
  withFirstAndLast: true,
  hidePageListOnlyOnePage: false,
  showTotal: true,
  nextPageText: <i className="fas fa-angle-right" />,
  prePageText: <i className="fas fa-angle-left" />,
  lastPageText: <i className="fas fa-angle-double-right" />,
  firstPageText: <i className="fas fa-angle-double-left" />,
  paginationTotalRenderer: customTotal,
  pageButtonRenderer,
  disablePageTitle: true,
  sizePerPageList: [10, 15, 30],
};

export default paginationOptions;
