import moment from "moment";
import React from "react";
import AvatarImage from "../../../../UI-components/Tables/AvatarImage";

const setCallsColumns = (handleUpdate, handleDelete, handleUpdateModal) => {
  return [
    {
      dataField: "manager",
      text: "Origin",
      formatter: (col) => {
        return <AvatarImage image={col.image} />;
      },
      headerStyle: () => ({
        width: "100px",
      }),
    },
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "notes", text: "Notes" },
    {
      dataField: "status",
      text: "Status",
      formatter: (col, row) => row.statusLabelJSX(),
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: (col, row) => row.priorityLabelJSX(),
    },
    {
      dataField: "callDateAt",
      text: "Date",
      formatter: (col) => moment(col).format("DD-MM-YYYY"),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (col, row) =>
        row.tableButtonsJSX(handleUpdate, handleDelete, handleUpdateModal),
    },
  ];
};
export default setCallsColumns;
