import * as types from "./auth-actionTypes";

export const loginStart = (loginForm) => ({
  type: types.LOGIN_START,
  loginForm,
});
export const loginSuccess = (user) => ({
  type: types.LOGIN_SUCCESS,
  user,
});
export const loginFail = (errorMsg) => ({
  type: types.LOGIN_FAIL,
  errorMsg,
});
export const loginReset = () => ({
  type: types.LOGIN_RESET,
});

export const logoutStart = () => ({
  type: types.LOGOUT_START,
});
export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});
export const logoutFail = () => ({
  type: types.LOGOUT_FAIL,
});

export const autoLoginStart = () => ({
  type: types.AUTO_LOGIN_START,
});
export const autoLoginSuccess = () => ({
  type: types.AUTO_LOGIN_SUCCESS,
});
export const autoLoginFail = () => ({
  type: types.AUTO_LOGIN_FAIL,
});
