import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InboundjunctionLogo from "../../../../../assets/images/InboundJunction-social.jpg";

const AuthAside = () => {
  return (
    <div
      className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-2.jpg")})`,
      }}
    >
      <div className="d-flex flex-row-fluid flex-column justify-content-between">
        <Link to="/" className="flex-column-auto mt-5">
          <img
            alt="Logo"
            className="w-100"
            src={InboundjunctionLogo}
            style={{ borderRadius: "8px" }}
          />
        </Link>

        <div className="flex-column-fluid d-flex flex-column justify-content-center">
          <h3 className="font-size-h1 mb-5 text-white">
            Welcome To InboundJunction CRM
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AuthAside;
