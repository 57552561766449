import React from "react";
import PropTypes from "prop-types";
import AssignedUser from "./AssignedUser/AssignedUser";

const AssignedUsers = ({ assignedUsers = [], onLoadProduct }) => {
  return (
    <>
      {assignedUsers.map((assignedUser) => (
        <AssignedUser
          key={assignedUser.id}
          assignedUser={assignedUser}
          onLoadProduct={onLoadProduct}
        />
      ))}
    </>
  );
};

AssignedUsers.propTypes = {
  assignedUsers: PropTypes.arrayOf(Object).isRequired,
};
export default AssignedUsers;
