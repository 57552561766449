import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import SVG from "react-inlinesvg";
import {backendURL, toAbsoluteUrl} from "../../../../_metronic/_helpers";
import * as actions from "../../../containers/Auth/auth-redux/auth-actions";
// eslint-disable-next-line import/no-cycle
// import User from "../../../API/Auth-API/AuthModel";

const UserQuick = ({ user }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  return (
    <div className="d-flex align-items-center mt-5">
      <div className="symbol symbol-100 mr-5">
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url(${backendURL()}${user.image})`,
          }}
        />
        <i className="symbol-badge bg-success" />
      </div>
      <div className="d-flex flex-column">
        <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
          {user.name}
        </span>
        <div className="text-muted mt-1">Job Position Should be here.</div>
        <div className="navi mt-2">
          <a href="#" className="navi-item">
            <span className="navi-link p-0 pb-2">
              <span className="navi-icon mr-1">
                <span className="svg-icon-lg svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Mail-notification.svg"
                    )}
                  />
                </span>
              </span>
              <span className="navi-text text-muted text-hover-primary">
                {user.email}
              </span>
            </span>
          </a>
        </div>

        <button
          type="button"
          className="btn btn-light-primary btn-bold"
          onClick={() => {
            dispatch(actions.logoutStart());
            push("/auth/login");
          }}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

UserQuick.propTypes = {
  // user: PropTypes.instanceOf(User).isRequired,
};

export default UserQuick;
