import React from "react";
import PropTypes from "prop-types";
import AssignedProduct from "./AssignedProduct/AssignedProduct";

const AssignedProducts = ({ assignedProjects, setUser }) => {
  return (
    <div className="col-12 row">
      {assignedProjects.map((assignedProject) => (
        <AssignedProduct
          key={assignedProject.id}
          assignedProject={assignedProject}
          setUser={setUser}
        />
      ))}
    </div>
  );
};

AssignedProducts.propTypes = {
  assignedProjects: PropTypes.arrayOf(Object).isRequired,
};

export default AssignedProducts;
