import React from "react";
import PropTypes from "prop-types";

const CardHeader = ({ title, children }) => (
  <div className="card-header border-0 py-5">
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label font-weight-bolder text-dark">{title}</span>
    </h3>
    {children}
  </div>
);

CardHeader.defaultProps = {
  children: "",
};
CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default CardHeader;
