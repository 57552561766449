import produce from "immer";
import * as types from "./auth-actionTypes";

const initialState = {
  loadingStates: {
    loadLogin: false,
    loadLogout: false,
    loadSignUp: false,
  },
  errorStates: {
    errorLogin: false,
    errorLogout: false,
    errorSignUp: false,
  },
  user: null,
};

// eslint-disable-next-line import/prefer-default-export
/* eslint-disable no-param-reassign */
export const authReducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    // LoginActions
    case types.LOGIN_START:
      draft.loadingStates.loadLogin = true;
      draft.errorStates.errorLogin = false;
      break;
    case types.LOGIN_SUCCESS:
      draft.loadingStates.loadLogin = false;
      draft.user = action.user;
      break;
    case types.LOGIN_FAIL:
      draft.loadingStates.loadLogin = false;
      draft.errorStates.errorLogin = action.errorMsg;
      break;
    case types.LOGIN_RESET:
      draft.loadingStates.loadLogin = false;
      break;
    // Logout Actions.
    case types.LOGOUT_START:
      draft.loadingStates.loadLogout = true;
      draft.errorStates.errorLogout = false;
      break;
    case types.LOGOUT_SUCCESS:
      draft.loadingStates.loadLogin = false;
      draft.errorStates.errorLogout = false;
      draft.user = null;
      break;
    case types.LOGOUT_FAIL:
      draft.loadingStates.loadLogout = false;
      draft.errorStates.errorLogout = action.errorMsg;
    // sign up Actions...
  }
}, initialState);
