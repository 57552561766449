import axiosInstance from "../API-config";
import { PRODUCTHUNT_ALL_USERS_VOTE_COMMENT } from "../../constants/constants";

class ProductHuntAPI {
  // Create A ProductHunt User
  static createUser(userForm) {
    return axiosInstance.post("/v2/product-hunt-users", { form: userForm });
  }

  // Get Single User
  static getUser(userId) {
    return axiosInstance.get(`/v2/product-hunt-user/${userId}`);
  }

  // Get All Users
  static getUsers() {
    return axiosInstance.get("/v2/product-hunt-users");
  }

  // Update ProductHunt User.
  static updateUser(updatedUser, userId) {
    return axiosInstance.put(`v2/product-hunt-user/${userId}`, {
      form: updatedUser,
    });
  }

  // Delete ProductHuntUser
  static deleteUser(userId) {
    return axiosInstance.delete(`v2/product-hunt-user/${userId}`);
  }

  // Create ProductHunt Project
  static createProject(projectForm) {
    const {
      projectImage,
      productHuntLink,
      projectName,
      projectDescription,
      assignKind,
      members,
    } = projectForm;
    let membersFormat;
    if (assignKind !== PRODUCTHUNT_ALL_USERS_VOTE_COMMENT) {
      membersFormat = members.map((user) => ({
        product_hunt_user_id: user.id,
        ask_vote: user.shouldVote,
        ask_comment: user.shouldComment,
      }));
    }
    const projectFormFormat = {
      url: productHuntLink,
      title: projectName,
      description: projectDescription,
      image: projectImage,
      assign_kind: assignKind,
      members: membersFormat,
    };
    return axiosInstance.post("v2/product-hunt-projects", {
      form: projectFormFormat,
    });
  }

  // Get All ProductHunter Projects
  static getProducts() {
    return axiosInstance.get("v2/product-hunt-projects");
  }

  // Update an assigned user Comment Or Vote Status.
  static updateProductVote(voteId, voteForm) {
    return axiosInstance.put(`/v2/product-hunt-vote/${voteId}`, {
      form: voteForm,
    });
  }

  // Get Single Product
  static getSingleProduct(productId) {
    return axiosInstance.get(`v2/product-hunt-project/${productId}`);
  }

  static getMyProfile() {
    return axiosInstance.get("/v2/product-hunt-users/my-profile");
  }
}

export default ProductHuntAPI;
