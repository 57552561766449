import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import styled from "styled-components";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const NavIcon = styled.i`
  color: ${({ isActive, color = "#3699FF" }) => (isActive ? `${color};` : ";")};
`;

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) =>
    checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  const asideMenuItems = [
    {
      route: "/dashboard",
      text: "Dashboard",
      icon: <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />,
    },
    {
      route: "/clients",
      text: "Clients",
      icon: (
        <NavIcon
          className="fa  fa-address-book"
          isActive={checkIsActive(location, "/clients")}
        />
      ),
    },
    {
      route: "/producthunt",
      text: "Product Hunt",
      icon: (
        <NavIcon
          className="fab fa-product-hunt"
          color="#DA552F"
          isActive={checkIsActive(location, "/producthunt")}
        />
      ),
    },
  ];

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {asideMenuItems.map(({ route, text, icon }) => (
          <li className={`menu-item ${getMenuItemActive(route)} `} key={route}>
            <NavLink className="menu-link" to={route}>
              <span className="svg-icon menu-icon">{icon}</span>
              <span className="menu-text">{text}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
}

// checkIsActive(location, "/clients") ? "text-primary" : ""
