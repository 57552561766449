import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { camelizeKeys } from "../../../../API/Api-utils";
import { APP_URL_DEFAULT } from "../../../../API/API-config";
import AssignedUsers from "./AssignedUsers/AssignedUsers";
import ProductHuntAPI from "../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";

const SingleProductView = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const onLoadProduct = (setInnerLoadingFalse = () => {}) => {
    ProductHuntAPI.getSingleProduct(id)
      .then((res) => setProduct(camelizeKeys(res.data.data.project)))
      .catch(() => toastr.error("Cannot Get Product"))
      .finally(() => {
        setInnerLoadingFalse();
      });
  };
  const {
    title,
    image,
    description,
    commentsCount,
    votesCount,
    assignedUsers,
  } = product || {};
  useEffect(() => {
    onLoadProduct();
  }, []);
  const assignedUsersSorted =
    assignedUsers && assignedUsers.sort((a, b) => a.id - b.id);
  return (
    <div className="row position-relative">
      {product && (
        <>
          <div className="col-md-12 col-lg-6">
            <div className="card bg-white p-5 ">
              <div className="card-header d-flex justify-content-around align-items-center">
                <img
                  src={`${APP_URL_DEFAULT}/${image}`}
                  style={{ width: "120px" }}
                  alt="product"
                />
                <h1>{title}</h1>
              </div>
              <div className="card-body">
                <p style={{ fontSize: "1.3rem" }}>{description}</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 bg-white d-flex justify-content-around align-items-center">
            <h1>Total Votes & Comments</h1>
            <span className="btn btn-light-primary btn-lg d-flex flex-row align-items-center font-size-h3">
              <i className="fa fa-thumbs-up" />
              {votesCount}
            </span>
            <span className="btn btn-light-info btn-lg d-flex flex-row align-items-center font-size-h3">
              <i className="fa fa-comment" />
              {commentsCount}
            </span>
          </div>
          <div className="col-12 mt-10">
            <div className="p-5">
              <h3>Assigned Product-Hunt Users</h3>
              <div className="row">
                <AssignedUsers
                  assignedUsers={assignedUsersSorted}
                  onLoadProduct={onLoadProduct}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleProductView;
