import axios from "axios";
import { getToken } from "../StorageAPI/StorageAPI";
import { backendURL } from "../../_metronic/_helpers";

const BACKEND_URL = backendURL();
export const APP_URL = `${BACKEND_URL}/api-time-entries/`;
export const APP_URL_DEFAULT = `${BACKEND_URL}/`;

// eslint-disable-next-line import/no-mutable-exports
const axiosInstance = axios.create({
  baseURL: APP_URL,
});

// sets authorization for request
axiosInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = getToken();
  return config;
});

export default axiosInstance;
