import React from "react";
import PropTypes from "prop-types";

const TableToolbar = ({ handleModal, btnText, icon }) => {
  return (
    <div className="card-toolbar">
      <button
        className="btn btn-outline-success font-weight-bolder font-size-sm"
        type="button"
        onClick={handleModal}
      >
        {icon && <i className={`${icon} mr-2`} />}
        {btnText}
      </button>
    </div>
  );
};

TableToolbar.defaultProps = {
  icon: null,
};
TableToolbar.propTypes = {
  handleModal: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default TableToolbar;
