import React, { useContext } from "react";
import PHContext from "../../../../../contexts/ProductHuntWizardContext";

const ProductHuntWizardFooter = () => {
  const { step, previousStep, submitPH } = useContext(PHContext);
  return (
    <div className="d-flex justify-content-between border-top mt-5 pt-10">
      {step >= 1 ? (
        <div className="mr-2">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
            onClick={previousStep}
          >
            Previous
          </button>
        </div>
      ) : (
        <div />
      )}
      <div>
        {step === 2 ? (
          <button
            type="submit"
            className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
            onClick={submitPH}
          >
            Submit
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
          >
            Next Step
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductHuntWizardFooter;
