import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardManager from "../Dashboard-manager/DashboardManager";
import ClientsManager from "../Clients-manager/Clients-manager";
import ProductHuntManager from "../ProductHunt-manager/ProductHuntManager";

const ManagerRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/dashboard">
          <DashboardManager />
        </Route>
        <Route path="/producthunt/">
          <ProductHuntManager />
        </Route>
        <Route path="/clients">
          <ClientsManager />
        </Route>
      </Switch>
    </>
  );
};

export default ManagerRoutes;
