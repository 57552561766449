import React from "react";
import PropTypes from "prop-types";
import { APP_URL_DEFAULT } from "../../../../../../API/API-config";

const ClientListItemHeader = ({ image, username, icount }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 mr-4 symbol symbol-60 symbol-square">
        <img src={`${APP_URL_DEFAULT}${image}`} alt="client" />
      </div>
      <div className="d-flex flex-column mr-auto">
        <div className="d-flex flex-column mr-auto">
          <span className="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">
            {username}
          </span>
          <span className="text-muted font-weight-bold">
            <i className="far fa-envelope-open mr-1" />
            {`iCount: ${(icount && icount.email) || "Missing Email"}`}
          </span>
        </div>
      </div>
    </div>
  );
};

ClientListItemHeader.defaultProps = {
  image: "",
  username: "",
  icount: "",
};
ClientListItemHeader.propTypes = {
  image: PropTypes.string,
  username: PropTypes.string,
  icount: PropTypes.string,
};

export default ClientListItemHeader;
