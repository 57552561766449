import React from "react";
import TableButtons from "../components/shared-components/Management/Management-components/sharedComponents/TableButtons";

class PHUser {
  constructor(phUser) {
    const { id, name, email, commentsCount, votesCount } = phUser;
    this.id = id;
    this.name = name;
    this.email = email;
    this.commentsCount = commentsCount;
    this.votesCount = votesCount;
  }

  tableButtonsJSX(handleDelete, handleUpdate) {
    return (
      <TableButtons
        handleDelete={() => handleDelete(this.id)}
        handleUpdateModal={() => handleUpdate(this)}
      />
    );
  }
}

export default PHUser;
