import React from "react";
import PropTypes from "prop-types";

const CardActionButton = ({ action, text, icon, btnClass }) => {
  return (
    <div className="card-toolbar">
      <button className={btnClass} type="button" onClick={action}>
        <i className={`${icon} mr-2`} />
        {text}
      </button>
    </div>
  );
};

CardActionButton.propTypes = {
  action: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  btnClass: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CardActionButton;
