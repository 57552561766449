import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectComp = ({
  name,
  control,
  options,
  defaultValue,
  isMulti = false,
  label,
}) => {
  const handleChange = (value) => {
    if (!value) return [];
    if (isMulti) {
      return value.map((val) => val.value);
    }
    return value.value;
  };
  return (
    <>
      {label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ onChange }) => (
          <Select
            isMulti={isMulti}
            options={options}
            onChange={(value) => onChange(handleChange(value, isMulti))}
            defaultValue={defaultValue}
          />
        )}
      />
    </>
  );
};

SelectComp.propTypes = {
  control: PropTypes.object,
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.object,
  ]),
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default SelectComp;
