import React, { useState, useEffect } from "react";
import ProductHuntProjectItem from "./ProductHuntListItem/ProductHuntProjectItem";
import ProductHuntNavigation from "../ProductHuntNavigation/ProductHuntNavigation";
import { camelizeKeys } from "../../../../API/Api-utils";
import ProductHuntAPI from "../../../../API/ProductHuntAdmin-API/ProductHuntAdminAPI";
import PageOverLayLoading from "../../../UI-components/Page/PageOverLayLoading";
import { toastr } from "react-redux-toastr";

const ProductHuntProjects = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    ProductHuntAPI.getProducts()
      .then((res) => setProducts(camelizeKeys(res.data.data.projects)))
      .catch(() => toastr.error("Fetching Products Failed"))
      .finally(() => setIsLoading(false));
  }, []);
  if (isLoading) {
    return <PageOverLayLoading isLoading />;
  }
  return (
    <div>
      <ProductHuntNavigation />
      <div className="row">
        {products.map((product) => (
          <ProductHuntProjectItem product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};

export default ProductHuntProjects;
