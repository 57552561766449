import { all, call } from "redux-saga/effects";
import { combineReducers } from "redux";

import { reducer as toastrReducer } from "react-redux-toastr";
import { authReducer } from "../app/containers/Auth/auth-redux/auth-reducer";
import {
  autoLogin,
  loginFlow,
} from "../app/containers/Auth/auth-redux/auth-sagas";
import managementReducer from "../app/components/shared-components/Management/Management-redux/Management-reducer";
import watchManagerManagement from "../app/components/shared-components/Management/Management-redux/Management-sagas";
import generalReducer from "./General-redux/General-reducer";
import watchManagerGeneral from "./General-redux/General-sagas";

export const rootReducer = combineReducers({
  toastr: toastrReducer,
  auth: authReducer,
  managerGeneral: generalReducer,
  managerManagement: managementReducer,
});

export function* rootSaga() {
  yield all([
    call(loginFlow),
    call(autoLogin),
    call(watchManagerManagement),
    call(watchManagerGeneral),
  ]);
}
