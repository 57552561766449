import React from "react";
import {
  numToColor,
  priorityToColor,
  priorityToString,
  statusToString,
} from "../utils/models-utils";
import ColoredLabel from "../components/shared-components/Management/Management-components/sharedComponents/ColoredLabel";
import TableButtons from "../components/shared-components/Management/Management-components/sharedComponents/TableButtons";

class TaskModel {
  constructor(task) {
    const {
      id,
      priority,
      status,
      users,
      client,
      manager,
      title,
      taskDateAt,
    } = task;
    this.id = id;
    this.priority = priority;
    this.taskDateAt = taskDateAt;
    this.status = status;
    this.users = users;
    this.client = client;
    this.manager = manager;
    this.title = title;
  }

  updateStatus() {
    const copyTask = { ...this };
    let usersToUserNames = this.users.map((user) => user.username);
    if (!usersToUserNames.length) {
      usersToUserNames = [this.manager.username];
    }
    if (copyTask.status === 1) {
      return {
        ...copyTask,
        status: 2,
        users: usersToUserNames,
      };
    }
    return {
      ...copyTask,
      status: 1,
      users: usersToUserNames,
    };
  }

  statusLabelJSX() {
    const statusString = statusToString(this.status);
    const color = numToColor(this.status);
    return <ColoredLabel labelText={statusString} color={color} />;
  }

  priorityLabelJSX() {
    const priorityString = priorityToString(this.priority);
    const color = priorityToColor(this.priority);
    return <ColoredLabel labelText={priorityString} color={color} />;
  }

  tableButtonsJSX(handleUpdate, handleDelete, handleUpdateModal) {
    return (
      <TableButtons
        handleUpdate={() => handleUpdate(this.updateStatus(), this.id)}
        handleDelete={() => handleDelete(this.id)}
        handleUpdateModal={() => handleUpdateModal(this)}
        status={this.status}
      />
    );
  }
}

export default TaskModel;
