import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientsSelector,
  isClientsLoadingSelector,
} from "../../../../../redux/General-redux/General-selectors";
import { startGetClients } from "../../../../../redux/General-redux/General-actions";
import PageOverLayLoading from "../../../UI-components/Page/PageOverLayLoading";
import ClientListItem from "./ClientsListItem/ClientsListItem";

const ClientsList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isClientsLoadingSelector);
  useEffect(() => {
    dispatch(startGetClients());
  }, []);
  const clients = useSelector(clientsSelector);
  return (
    <div className="row position-relative">
      <PageOverLayLoading isLoading={isLoading} />
      {clients &&
        clients.map((client) => (
          <div key={client.id} className="col-xl-4">
            <ClientListItem client={client} />
          </div>
        ))}
    </div>
  );
};
export default ClientsList;
