import React from "react";

const FormSubmitError = ({ errorMessage }) => {
  if (!errorMessage) return null;
  return (
    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
      <div className="alert-text font-weight-bold">{errorMessage}</div>
    </div>
  );
};

export default FormSubmitError;
