// eslint-disable-next-line import/prefer-default-export
export const priorities = {
  PRIORITY_LOW: 1,
  PRIORITY_MED: 3,
  PRIORITY_HIGH: 3,
};
export const callStatuses = {
  CALL_STATUS_SUMMARIZED: 1,
  CALL_STATUS_SCHEDULED: 2,
};

export const PRODUCTHUNT_ALL_USERS_COMMENT = 3;
export const PRODUCTHUNT_ALL_USERS_VOTE = 1;
export const PRODUCTHUNT_ALL_USERS_VOTE_COMMENT = 2;
