import React from "react";
import PropTypes from "prop-types";
import { inputValidationState } from "./Formik-utils/Formik-utils";

const CustomTextArea = ({ field, form }) => {
  const { errors, touched } = form;
  return (
    <div className="form-group">
      <label className="d-block" htmlFor={field.name}>
        Summary Of The Call
      </label>
      <textarea
        className={[
          inputValidationState(touched.summaryText, errors.summaryText),
        ]}
        name={field.name}
        value={field.value}
        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
        onBlur={field.onBlur}
      />
      {errors.summaryText && (
        <div className="invalid-feedback">{errors.summaryText}</div>
      )}
    </div>
  );
};

CustomTextArea.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
};
export default CustomTextArea;
