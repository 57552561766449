import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { APP_URL_DEFAULT } from "../../../API/API-config";

export const Tooltip = styled.span`
  position: relative;
  display: inline-block;
  span {
    background-color: #fff;
    visibility: hidden;
    width: 120px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    position: absolute;
    box-shadow: 0px 0px 5px 0px #0000003b;
    color: #363636;
    font-weight: 500;
    z-index: 10;
    position: absolute;
    text-align: center;
    min-width: 75px;
  }
  &:hover span {
    display: inline-block;
    visibility: visible;
  }
`;

const UsersImageGroup = ({ users }) => {
  return (
    <div className="symbol-group symbol-hover">
      {users.map(({ image, username }) => (
        <Tooltip
          key={username}
          className="symbol symbol-30 symbol-circle"
          data-toggle="tooltip"
        >
          <img alt="User" src={`${APP_URL_DEFAULT}${image}`} />
          <span>{username}</span>
        </Tooltip>
      ))}
    </div>
  );
};

UsersImageGroup.propTypes = {
  users: PropTypes.array,
};

export default UsersImageGroup;
