/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import AuthAside from "../auth-components/authAside/authAside";

function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <AuthAside />
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <Route exact path="/auth/login">
                  <Login />
                </Route>
                <Route>
                  <Redirect to="/auth/login" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthPage;
