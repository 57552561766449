import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: #afafaf8f;
  cursor: progress;
  border-radius: 16px;
`;

const PageOverLayLoading = ({ isLoading }) => {
  if (!isLoading) return <div />;
  return (
    <div className="position-relative h-100 w-100">
      <Overlay />
    </div>
  );
};

PageOverLayLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default PageOverLayLoading;
