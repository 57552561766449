import React from "react";
import ColoredLabel from "../components/shared-components/Management/Management-components/sharedComponents/ColoredLabel";
import {
  statusToString,
  priorityToString,
  numToColor,
  priorityToColor,
} from "../utils/models-utils";
import TableButtons from "../components/shared-components/Management/Management-components/sharedComponents/TableButtons";

class CallModel {
  constructor(call) {
    const { priority, status, id, manager, client, notes, callDateAt } = call;
    this.priority = priority;
    this.status = status;
    this.manager = manager;
    this.client = client;
    this.callDateAt = callDateAt;
    this.notes = notes;
    this.id = id;
  }

  updateStatus() {
    const copyCall = { ...this };
    if (copyCall.status === 1) {
      return { ...copyCall, status: 2 };
    }
    return { ...copyCall, status: 1 };
  }

  statusLabelJSX() {
    const statusString = statusToString(this.status);
    const color = numToColor(this.status);
    return <ColoredLabel labelText={statusString} color={color} />;
  }

  priorityLabelJSX() {
    const priorityString = priorityToString(this.priority);
    const color = priorityToColor(this.priority);
    return <ColoredLabel labelText={priorityString} color={color} />;
  }

  tableButtonsJSX(handleUpdate, handleDelete, handleUpdateModal) {
    return (
      <TableButtons
        handleUpdate={() => handleUpdate(this.updateStatus(), this.id)}
        handleDelete={() => handleDelete(this.id)}
        handleUpdateModal={() => handleUpdateModal(this)}
        status={this.status}
      />
    );
  }
}

export default CallModel;
