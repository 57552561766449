import React from "react";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  getErrorSelector,
  loginLoadingSelector,
} from "../auth-redux/auth-selectors";
import { inputValidationState } from "../../../components/UI-components/Formik/Formik-utils/Formik-utils";
import { loginStart } from "../auth-redux/auth-actions";

function Login() {
  const dispatch = useDispatch();
  const isLoading = useSelector(loginLoadingSelector);
  const loginError = useSelector(getErrorSelector("errorLogin"));
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("This Field Is Required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("This Field Is Required"),
  });
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      <Formik
        validationSchema={loginSchema}
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={(e) => dispatch(loginStart(e))}
      >
        {({ errors, touched, handleSubmit }) => (
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            onSubmit={handleSubmit}
          >
            {loginError ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{loginError}</div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group form-control-s fv-plugins-icon-container">
              <label htmlFor="username">User Name</label>
              <Field
                name="username"
                as="input"
                className={`${inputValidationState(
                  touched.username,
                  errors.username
                )} form-control-solid p-6`}
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="password">Password</label>
              <Field
                name="password"
                as="input"
                type="password"
                className={`${inputValidationState(
                  touched.password,
                  errors.password
                )} form-control-solid p-6`}
              />
            </div>
            <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
              <button
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
              >
                <span>Sign In</span>
                {isLoading && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default injectIntl(Login);
