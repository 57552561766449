import React, { useState } from "react";
import moment from "moment";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker/es";

const DatePickerComp = ({ control }) => {
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"));
  const handleChange = (val) => {
    const newDate = moment(val).format("DD-MM-YYYY");
    setDate(newDate);
    return newDate;
  };
  return (
    <>
      <Controller
        name="date"
        control={control}
        render={({ onChange }) => (
          <DatePicker
            value={date}
            onChange={(val) => onChange(handleChange(val))}
            className="form-control"
          />
        )}
      />
    </>
  );
};

export default DatePickerComp;
