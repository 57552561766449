import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const CustomSelect = ({
  field,
  form,
  options,
  label = "",
  isMulti = false,
  defaultValue,
}) => {
  useEffect(() => {}, []);
  return (
    <div className="form-group">
      <label>{label}</label>
      <Select
        defaultValue={defaultValue}
        isMulti={isMulti}
        options={options}
        name={field.name}
        onChange={(option) => {
          if (!option) {
            return form.setFieldValue(field.name, "");
          }
          if (isMulti) {
            return form.setFieldValue(field.name, option);
          }
          return form.setFieldValue(field.name, option.value);
        }}
        onBlur={field.onBlur}
      />
      {form.errors[field.name] && (
        <div className="invalid-feedback d-block">
          {form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
};

export default CustomSelect;
