import produce from "immer";
import * as types from "./General-actionTypes";

const initialState = {
  loadingStates: {
    getClients: false,
    getManagers: false,
  },
  clients: [],
  managers: [],
};
// eslint-disable-next-line import/prefer-default-export
/* eslint-disable no-param-reassign */
const generalReducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case types.START_GET_MANAGERS:
      draft.loadingStates.getManagers = true;
      break;
    case types.SUCCESS_GET_MANAGERS:
      draft.loadingStates.getManagers = false;
      draft.managers = action.managers;
      break;
    case types.FAIL_GET_MANAGERS:
      draft.loadingStates.getManagers = false;
      break;

    case types.START_GET_CLIENTS:
      draft.loadingStates.getClients = true;
      break;
    case types.SUCCESS_GET_CLIENTS:
      draft.loadingStates.getClients = false;
      draft.clients = action.clients;
      break;
    case types.FAIL_GET_CLIENTS:
      draft.loadingStates.getClients = false;
      break;
  }
}, initialState);

export default generalReducer;
